import { Alert } from "@material-ui/lab";
import { Button, Grid } from "@material-ui/core";
import { useState } from "react";

import useAccounts from "../../../hooks/useAccounts";
import useApi from "../../../hooks/useApi";
import useQuery from "../../../hooks/useQuery";

import LoadedContent from "../../ui/LoadedContent";

export default function Cache() {
    const { account } = useAccounts();
    const { sendPost } = useApi();
    const { data, isLoaded, refresh } = useQuery( 'admin/system/cache' );

    const [ message, setMessage ] = useState( '' );
    const [ messageType, setMessageType ] = useState<any>( '' );

    const requestPartialScan = () => requestScan( 'partial' );

    const requestFullScan = () => requestScan( 'full' );

    const requestScan = async ( type: string ) => {
        try {
            const response = await sendPost( 'admin/system/cache', { type: type, account: account.code } );
    
            setMessageType( 'success' );
            setMessage( response.message );
        } catch ( error: any ) {
            setMessageType( 'error' );
            setMessage( error.message );
        }
    }

    return <LoadedContent loaded={isLoaded}>
        <div className="page-cache">
            <h1>{account.name}</h1>

            {
                message
                ? <Grid container justifyContent="center" className="message">
                    <Grid container xs={4} justifyContent="center">
                        <Grid item>
                            <Alert severity={messageType}>{message}</Alert>
                        </Grid>
                    </Grid>
                </Grid>
                : ''
            }

            <Grid container justifyContent="center" className="tables">
                <Grid container xs={4} spacing={2}>
                    <Grid item xs={12}><h2>Last Scanned</h2></Grid>
                    <Grid item xs={3}>Account</Grid>
                    <Grid item xs={9}>{data?.last_scan.account}</Grid>
                    <Grid item xs={3}>Campaigns</Grid>
                    <Grid item xs={9}>{data?.last_scan.campaigns}</Grid>
                    <Grid item xs={3}>Adgroups</Grid>
                    <Grid item xs={9}>{data?.last_scan.adgroups}</Grid>
                    <Grid item xs={3}>Ads</Grid>
                    <Grid item xs={9}>{data?.last_scan.ads}</Grid>
                </Grid>

                <Grid container xs={4} spacing={0}>
                    <Grid item xs={12}><h2>In Queue</h2></Grid>
                    <Grid item xs={3}>Partial</Grid>
                    <Grid item xs={9}>{data?.in_queue.partial ?? 'No'}</Grid>
                    <Grid item xs={3}>Full</Grid>
                    <Grid item xs={9}>{data?.in_queue.full ?? 'No'}</Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" className="buttons">
                <Grid container xs={4} justifyContent="center">
                    <Grid item>
                        <Button onClick={requestPartialScan}>Request Partial Scan</Button>&nbsp;
                        <Button onClick={requestFullScan}>Request Full Scan</Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </LoadedContent>;
}
