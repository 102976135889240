import { Container } from '@material-ui/core';
import { Switch } from 'react-router-dom';
import axios from 'axios';
import React, { Fragment } from 'react';

import AuthRoute from './components/AuthRoute';
import LoadedContent from './components/ui/LoadedContent';
import useAccounts from './hooks/useAccounts';
import useAuth from './hooks/useAuth';
import useBranding from './hooks/useBranding';
import Menu from './components/Menu';

import systemRoutes from './Routes';
import { routes as clientRoutes } from './clients';

import './vendor/slick/slick.css';
import './vendor/slick/slick-theme.css';

// Use different port for local development because backend and frontend aren't
// connected like in prod.
if ( window.location.host.match( /(localhost|\.test):3000/ ) ) {
    axios.defaults.baseURL = window.location.origin.replace( '3000', '8335' ) + '/api/';
} else {
    axios.defaults.baseURL = '/api';
}

export default function App() {
    const { isLoggedIn } = useAuth();

    const { loaded: isBrandingLoaded, error: brandingError } = useBranding();
    const { account, loaded: isAccountsLoaded, error: accountsError } = useAccounts();

    const currentRoutes = clientRoutes[ account.code ] ?? {} ;

    return <LoadedContent
        loaded={isBrandingLoaded && isAccountsLoaded}
        error={brandingError ? brandingError : accountsError ? accountsError : ''}
        footer={isLoggedIn}
    >
        <div className="full-height">
            <Fragment>
                { isLoggedIn ? <Menu /> : '' }
                <Container maxWidth="lg" className="full-height content">
                    <Switch>
                        { Object.entries(currentRoutes).map(
                            ( [path, component] ) => <AuthRoute key={path} path={'/' + path} component={component} exact={true} />
                        ) }

                        { systemRoutes.map(
                            ( item ) => <AuthRoute key={item.path} {...item} />
                        ) }
                    </Switch>
                </Container>
            </Fragment>
        </div>
    </LoadedContent>;
}
