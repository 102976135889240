import useFields from '../../../hooks/useFields';
import ColumnSelectTable from '../../../components/ui/ColumnSelectTable';

export default function Search() {
    const { getFields } = useFields();

    const fieldSets = getFields( [ 'ga_terms' ] );

    let set = 'ga_terms';

    return <ColumnSelectTable
        title='Google Search Terms'
        url='google/terms'
        dimensionParams={{ platform: 'google' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
        // wrap={true}
    />
}
