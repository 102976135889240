import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ReusableProvider } from 'reusable';

import App from './App';

import './css/app.scss';

render(
    <React.StrictMode>
        <BrowserRouter>
            <ReusableProvider>
                <App />
            </ReusableProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById( 'app' )
);
