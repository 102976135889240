import { Popover } from "@material-ui/core";
import { MutableRefObject, useState } from "react";
import Select from "./Select";
import { ColumnSet } from "./Table";

interface Props {
    id?: string;
    className?: string;

    open: boolean;
    onClose: any;
    onChange: any;

    anchor: MutableRefObject<any>;

    columns: ColumnSet[];
    value?: string;
}

// Use anchor position instead of preferred anchor element to be compatible with
// case where child elements get re-rendered (ex: triggering open with state
// change). This makes anchor element not work since it gets removed from DOM
// during re-render.
export default function ColumnSelectPopover( props: Props ) {
    const [ value, setValue ] = useState( props.value ?? props.columns[0]?.code ?? undefined );

    const list = props.columns.map( (item: ColumnSet) => {
        return {
            text: item.name,
            value: item.code
        }
    } );

    return <Popover
        id={props.id}

        open={props.open}

        classes={{
            root: 'popover ' + ( props.className ?? '' )
        }}

        anchorReference="anchorPosition"
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}

        anchorPosition={ ( () => {
            const box = props.anchor?.current?.getBoundingClientRect();

            return {
                top: box?.top + box?.height,
                left: box?.left + box?.width + 12
            };
        } )() }
    >
        <Select
            id='popover'
            className={ 'popover ' + ( props.className ?? '' ) }
            open={true}
            items={list}
            value={value}
            onClose={props.onClose}
            onChange={ ( id: string, value: string ) => {
                props.onChange( value );
                setValue( value );
            } }
        />
    </Popover>
}
