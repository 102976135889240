import useFields from '../../../hooks/useFields';
import ColumnSelectTable from '../../../components/ui/ColumnSelectTable';

export default function Pacing() {
    const { getFields } = useFields();

    const fieldSets = getFields( [ 'fb_pacing' ] );

    let set = 'fb_pacing';

    return <ColumnSelectTable
        title='Facebook Pacing'
        url='facebook/pacing'
        dimensionParams={{ platform: 'facebook' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
        enableDates={false}
    />
}
