import useFields from '../../../../hooks/useFields';
import ColumnSelectTable from '../../../../components/ui/ColumnSelectTable';

export default function Campaigns() {
    const { getFields, removeFields } = useFields();

    const fieldSets = removeFields(
        [ /^(ad|adgroup)?_/ ],
        getFields( [
            'fb_setup_campaign',
            'fb_aware', 'fb_aware_full'
        ] )
    );

    const set = 'fb_aware';

    return <ColumnSelectTable
        title='Facebook Awareness Campaigns'
        url='facebook/campaigns/awareness'
        dimensionParams={{ platform: 'facebook', type: 'awareness' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
    />
}
