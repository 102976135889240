// import FacebookBudgets from '../../../components/facebook/Budgets';

import FacebookAds from './facebook/Ads';
import FacebookAudiences from './facebook/Audiences';
import FacebookCampaigns from './facebook/Campaigns';
import FacebookCreatives from './facebook/Creatives';
import FacebookPacing from './facebook/Pacing';
import FacebookSummary from './facebook/Summary';
import GoogleAds from './google/Ads';
import GoogleKeywords from './google/Keywords';
import GooglePacing from './google/Pacing';
import GoogleShopping from './google/Shopping';
import GoogleSummary from './google/Summary';
import GoogleTerms from './google/Terms';
import ShopifyOrders from './shopify/Orders';
import ShopifySummary from './shopify/Summary';
import TikTokCreatives from './tiktok/Creatives';
import TikTokSummary from './tiktok/Summary';

const defaultRoutes = {
    'facebook': {
        'facebook/ads': FacebookAds,
        'facebook/audiences': FacebookAudiences,
        'facebook/campaigns': FacebookCampaigns,
        'facebook/creatives': FacebookCreatives,
        'facebook/summary': FacebookSummary,
        'facebook/pacing': FacebookPacing
    },

    'google': {
        'google/ads': GoogleAds,
        'google/keywords': GoogleKeywords,
        'google/pacing': GooglePacing,
        'google/shopping': GoogleShopping,
        'google/summary': GoogleSummary,
        'google/terms': GoogleTerms
    },
    
    'shopify': {
        'shopify/orders': ShopifyOrders,
        'shopify/summary': ShopifySummary
    },

    'tiktok': {
        'tiktok/creatives': TikTokCreatives,
        'tiktok/summary': TikTokSummary
    }
}

const routeLists = Object.values(defaultRoutes);

let routes = {};

for ( let route of routeLists ) {
    routes = Object.assign( routes, route );
}

export { routes, defaultRoutes };
