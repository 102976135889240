import useFields from '../../../hooks/useFields';
import ColumnSelectTable from '../../../components/ui/ColumnSelectTable';

export default function Keywords() {
    const { getFields } = useFields();

    const fieldSets = getFields( [ 'ga_keywords' ] );

    let set = 'ga_keywords';

    return <ColumnSelectTable
        title='Google Keywords'
        url='google/keywords'
        dimensionParams={{ platform: 'google' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
    />
}
