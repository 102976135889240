const exportCsv = ( name: string, rows: any[][] ) => {
    const delimiter = "\t";

    // Prepare data.

    const data = rows.map( row => row.join( delimiter ) ).join( "\n" );

    // Download file.

    // Create a link to click and initiate download.
    const a = document.createElement( 'a' );

    a.href = URL.createObjectURL( new Blob( [data], { type: 'text/csv' } ) );
    a.setAttribute( 'download', name + '.csv' );
    document.body.appendChild( a );
    a.click();

    // Remove it after we're done.
    document.body.removeChild( a );
}

export default function useExport() {
    return { exportCsv };
}
