import * as Alchemy from './alchemy';
import * as Alchemy2 from './alchemy2';
import * as Falscara from './falscara';
import * as Impress from './impress';
import * as Joah from './joah';
import * as Juara from './juara';
import * as Kiss from './kiss';
import * as Colors from './kisscolors';

const list = [ Alchemy, Alchemy2, Colors, Falscara, Impress, Joah, Juara, Kiss ];

let menus: any = {};
let routes: any = {};

for ( let item of list ) {
    menus[ item.code ] = item.menus;
    routes[ item.code ] = item.routes;
}

export { menus, routes };
