import { ColumnSetHash } from "../../ui/Table"
import ColumnSelectTable from "../../ui/ColumnSelectTable"

export default function Pacing() {
    const sets: ColumnSetHash = {
        'default': {
            name: 'Default',
            code: 'default',
            fields: [
                {
                    name: 'Channel',
                    field: 'col1'
                },
                {
                    name: 'Spend',
                    field: 'col2',
                    type: 'money'
                },
                {
                    name: 'Revenue',
                    field: 'col3',
                    type: 'money'
                },
                {
                    name: 'ROAS',
                    field: 'col4'
                }
            ]
        }
    };

    return <ColumnSelectTable
        title='Pacing'
        url='admin/tools/pacing'
        dimensionParams={{ platform: 'x' }}
        columnSets={sets}
        columnSet='default'
    />
}
