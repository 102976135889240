import { Box, Grid, Paper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useBranding from "../../hooks/useBranding";

import LoadedContent from "./LoadedContent";

interface Props {
    children: React.ReactNode | null;
    error?: string;
    message?: string;

    onSubmit?: any;
}

export default function LoginForm( props: Props ) {
    const { branding, loaded } = useBranding();

    const onSubmit = ( event: any ) => {
        // Prevent page reload.
        event.preventDefault();

        if ( props.onSubmit ) {
            props.onSubmit();
        }
    }

    return <LoadedContent loaded={loaded}>
        <Box className="full-height page-login" display="flex" alignItems="center">
            <div className="full-width">
                <Paper elevation={3}>
                    <Grid container className="full-height" alignItems="center">
                        <Grid item xs={6} className="full-height panel-image" style={{backgroundImage: `url(${branding.login_image})`}}></Grid>
                        <Grid item xs={6} className="panel-form">
                            { branding.name !== 'CHMW'
                                ?  <div className="client-logo"><img src={branding.logo_client_light} alt={branding.name} /></div>
                                : ''
                            }

                            { props.message ? <Alert severity="success">{props.message}</Alert> : '' }
                            { props.error ? <Alert severity="error">{props.error}</Alert> : '' }

                            <form onSubmit={onSubmit}>
                                {props.children}
                            </form>
                        </Grid>
                    </Grid>
                </Paper>
                <div className="brand-logo"><img src={branding.logo_brand_light} alt={branding.brand_name} /></div>
            </div>
        </Box>
    </LoadedContent>;
}
