export default function useSession() {
    const destroySession = () => {
        localStorage.clear();
    }

    const getSession = ( key: string, object: boolean = false, encrypt: boolean = false, defaultValue: any = null ) => {
        let value = localStorage.getItem( key );

        if ( value === null ) {
            return defaultValue;
        }

        if ( encrypt && value ) {
            value = atob( value );
        }

        // Objects are stored as JSON strings.
        if ( object ) {
            value = value ? JSON.parse( value ) : null;
        }

        return value;
    }

    const setSession = ( key: string, value: any, object: boolean = false, encrypt: boolean = false ) => {
        let storedValue = object ? JSON.stringify( value ) : value;

        if ( encrypt ) {
            storedValue = btoa( storedValue );
        }

        localStorage.setItem( key, storedValue );
    }

    const unsetSession = ( key: string ) => {
        localStorage.removeItem( key );
    }

    return {
        destroySession,
        getSession,
        setSession,
        unsetSession
    };
}
