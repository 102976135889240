import useFields from '../../../../hooks/useFields';
import ColumnSelectTable from '../../../../components/ui/ColumnSelectTable';

export default function Campaigns() {
    const { getFields, removeFields } = useFields();

    const fieldSets = removeFields(
        [ /^(ad|adgroup)?_/ ],
        getFields( [
            'fb_setup_campaign',
            'fb_ecom', 'fb_ecom1', 'fb_ecom_funnel', 'fb_ecom_funnel2', 'fb_ecom_funnel3'
        ] )
    );

    const set = 'fb_ecom';

    return <ColumnSelectTable
        title='Facebook Ecommerce Campaigns'
        url='facebook/campaigns/ecom'
        dimensionParams={{ platform: 'facebook', type: 'ecom' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
    />
}
