import useAuth from '../../../hooks/useAuth';
import useFields from '../../../hooks/useFields';
import useFilters from '../../../hooks/useFilters';

import { TabCards } from '../../../components/ui/TabCards';

export default function Summary() {
    const { canView } = useAuth();
    const { filters } = useFilters();
    const { getFieldsFromTabCards, getLayout } = useFields();

    const publicTabs: any = [
        {
            name: 'Overview',
            cards: getLayout( [
                [ 'spend', 'impressions', 'cpm' ],
                [ 'ctr', 'link_ctr' ],
                [ 'purchases', 'purchase_rate', 'aov' ],
                [ 'revenue', 'roas_float' ]
            ] )
        }
    ];

    const allTabs: any = publicTabs.concat([
        {
            name: 'ROAS',
            cards: getLayout( [
                [ 'roas' ],
                [ 'click_dropoff', 'roas_cd', 'click_dropoff_spend' ],
                [ 'cpm', 'roas_cpm' ]
            ] )
        },
        {
            name: 'Goals',
            cards: getLayout( [
                [ 'clicks',  'ctr' ],
                [ 'link_clicks', 'link_ctr' ],
                [ 'add_to_cart_rate', 'initiate_checkout_rate', 'purchase_rate' ],
                [ 'add_to_carts', 'initiate_checkouts', 'purchases' ]
            ] )
        },
        {
            name: 'Dropoffs',
            cards: getLayout( [
                [ 'landing_page_views', 'purchases' ],
                [ 'link_clicks', 'click_dropoff', 'click_dropoff_spend' ],
                [ 'add_to_carts', 'cart_dropoff', 'cart_dropoff_value' ],
                [ 'initiate_checkouts', 'checkout_dropoff', 'checkout_dropoff_value' ],
            ] )
        },
        {
            name: 'Funnel',
            cards: getLayout( [
                [ 'spend' ],
                [ 'impressions', 'reach' ],
                [ 'link_clicks', 'cost_per_link_click' ],
                [ 'landing_page_views', 'cost_per_landing_page_view' ],
                [ 'view_contents', 'view_contents_value' ],
                [ 'add_to_carts', 'add_to_carts_value' ],
                [ 'initiate_checkouts', 'initiate_checkouts_value' ],
                [ 'purchases', 'revenue' ]
            ] )
        }
    ]);

    if ( !canView( filters.view, 'user' ) ) {
        return <TabCards
            title='Facebook'
            api='facebook/summary'
            params={{ fields: getFieldsFromTabCards(publicTabs).join( ',' ) }}
            dimensionParams={{ platform: 'facebook' }}
            enableCompare={true}
            tabs={publicTabs}
        />
    }

    return <TabCards
        title='Facebook Summary'
        api='facebook/summary'
        params={{ fields: getFieldsFromTabCards(allTabs).join( ',' ) }}
        dimensionParams={{ platform: 'facebook' }}
        tabs={allTabs}
        enableCompare={true}
        enableSave={true}
    />
}
