import { Grid } from "@material-ui/core";
import { Fragment } from "react";
import useQuery from "../../../hooks/useQuery";
import LoadedContent from "../../ui/LoadedContent";
import { Tabs, TabsContent } from "../../ui/Tabs";
import { Column, Table } from "../../ui/Table";

export default function Status() {
    const { data, error, isLoaded } = useQuery( 'admin/system/status' );

    const rangeColumns: Column[] = [
        {
            name: 'Account',
            field: 'account'
        },
        {
            name: 'Name',
            field: 'name'
        },
        {
            name: 'Active',
            field: 'active'
        },
        {
            name: 'Latest',
            field: 'max',
            type: 'date'
        },
        {
            name: 'Oldest',
            field: 'min',
            type: 'date'
        }
    ]

    return <LoadedContent loaded={isLoaded} error={error}>
        <h1>System Status</h1>
        <Tabs>
            <TabsContent name="Facebook">
                <Table
                    columns={rangeColumns}
                    data={ data?.range ?? [] }
                />
            </TabsContent>
            <TabsContent name="Queue">
                <h2>Pending</h2>
                <Grid container xs={6}>
                    { data?.queue?.pending?.map( (item: any) =>
                        <Fragment>
                            <Grid item xs={6}>{item.name}</Grid>
                            <Grid item xs={6}>{item.count}</Grid>
                        </Fragment>
                    ) }
                </Grid>

                <h2>Completed Today</h2>
                <Grid container xs={6}>
                    { data?.queue?.today?.map( (item: any) =>
                        <Fragment>
                            <Grid item xs={6}>{item.name}</Grid>
                            <Grid item xs={6}>{item.count}</Grid>
                        </Fragment>
                    ) }
                </Grid>

                <h2>Completed This Week</h2>
                <Grid container xs={6}>
                    { data?.queue?.week?.map( (item: any) =>
                        <Fragment>
                            <Grid item xs={6}>{item.name}</Grid>
                            <Grid item xs={6}>{item.count}</Grid>
                        </Fragment>
                    ) }
                </Grid>
            </TabsContent>
            <TabsContent name="Facebook">
                <h2>Updated</h2>
                <Grid container xs={6}>
                    { data?.facebook?.updated?.map( (item: any) =>
                        <Fragment>
                            <Grid item xs={6}>{item.name}</Grid>
                            <Grid item xs={6}>{item.updated}</Grid>
                        </Fragment>
                    ) }
                </Grid>

                <h2>Queue</h2>
                <Grid container xs={6}>
                    { data?.facebook?.queue?.map( (item: any) =>
                        <Fragment>
                            <Grid item xs={6}>{item.name}</Grid>
                            <Grid item xs={6}>{item.count}</Grid>
                        </Fragment>
                    ) }
                </Grid>
            </TabsContent>
        </Tabs>
    </LoadedContent>
}
