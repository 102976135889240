import { Grid, InputAdornment, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";

import useDate, { DateRange } from "../../../hooks/useDate";
import useFormat from '../../../hooks/useFormat';

import DateRangePicker from "../../ui/DateRangePicker";

export default function BudgetCalculator() {
    const { addDays, getDate, getDifferenceInDays } = useDate();
    const { formatMoney } = useFormat();

    const [ budget, setBudget ] = useState('');
    const [ spent, setSpent ] = useState('');
    
    const [ startDate, setStartDate ] = useState( getDate( 'n/j/y' ) );
    const [ endDate, setEndDate ] = useState( getDate( 'n/t/y'));
    
    const [ percent, setPercent ] = useState('100');
    const [ dailyBudget, setDailyBudget ] = useState(0);
    const [ error, setError ] = useState('');

    const getNumDays = ( start?: string, end?: string ): number => {
        // Use +1 to include the full interval.
        return getDifferenceInDays( start ?? startDate, end ?? endDate ) + 1;
    }

    const [ days, setDays ] = useState( getNumDays().toString() );

    const calculate = () => {
        const values = [ budget, spent, days, percent ];

        const empty = values.reduce(
            ( empty, value ) => value ? empty : true,
            false
        );

        // If a value is empty, clear output.
        if ( empty ) {
            setDailyBudget(0);
            return;
        }

        const valid = values.reduce(
            ( valid, value ) => ( !value || isValid( value ) ) ? valid : false
            , true
        );

        // If a value isn't valid, clear the output.
        if ( !valid ) {
            setError('Invalid input.')
            return;
        }

        if ( error ) {
            setError('');
        }

        setDailyBudget( ( parse( budget) - parse(spent) ) / parse(days) * 100 / parse(percent) );
    }

    const isError = ( text: string ): boolean => text && !isValid( text ) ? true : false

    const isValid = ( text: string ): boolean => text.trim().match( /^[0-9\.,]+$/ ) ? true : false

    const parse = ( text: string ): number => parseFloat( text ? text.replace( ',', '' ) : '0' )

    const setDatesFromDays = () => {
        if ( !isValid( days ) ) {
            return;
        }

        // TODO: This should be parseFloat - 1 but is removed because timezone issue already pushes date back 1 day.
        setEndDate( getDate( 'n/j/y', addDays( startDate, parseFloat( days ) ) ) );
    }

    const setDaysFromDates = () => {
        setDays( getNumDays().toString() );
    }

    // Use useEffect because each date is set manually so we need to wait for async to finish.
    useEffect( () => {
        calculate();
    }, [ startDate, endDate ] )

    return <>
        <h1>Daily Budget Calculator</h1>

        <Grid container justifyContent="center" className="budget-calculator">
            <Grid item xs={3}>
                <Grid container className="row">
                    <Grid item xs={4} className="label">Budget</Grid>
                    <Grid item xs={8}>
                        <TextField
                            variant="outlined"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            value={ budget ? budget : '' }
                            error={isError(budget)}
                            onChange={ ( event ) => setBudget( event.target.value ) }
                            onBlur={calculate}
                        />
                    </Grid>
                </Grid>

                <Grid container className="row">
                    <Grid item xs={4} className="label">Spent</Grid>
                    <Grid item xs={8}>
                        <TextField
                            variant="outlined"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            value={ spent ? spent : '' }
                            error={isError(spent)}
                            onChange={ ( event ) => setSpent( event.target.value ) }
                            onBlur={calculate}
                        />
                    </Grid>
                </Grid>

                <Grid container className="row">
                    <Grid item xs={4} className="label">Dates</Grid>
                    <Grid item xs={8} className="date-select">
                        <div className="date-control">
                            <DateRangePicker
                                start={startDate}
                                end={endDate}
                                // Allow picking up to 3 months ahead
                                max={getDate( 'n/t/y', addDays( Date.now(), 90 ) )}
                                ranges={[]}
                                onChange={ ( range: DateRange ) => {
                                    setStartDate( range.start );
                                    setEndDate( range.end );
                                    setDays( getNumDays( range.start, range.end ).toString() );
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container className="row">
                    <Grid item xs={4} className="label">Days</Grid>
                    <Grid item xs={8}>
                        <TextField
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">days</InputAdornment>
                            }}
                            value={days}
                            error={isError(days)}
                            onChange={ ( event ) => setDays( event.target.value ) }
                            onBlur={ () => { setDatesFromDays(); calculate(); } }
                        />
                    </Grid>
                </Grid>

                <Grid container className="row">
                    <Grid item xs={4} className="label">Actualized</Grid>
                    <Grid item xs={8}>
                        <TextField
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            value={percent}
                            error={isError(percent)}
                            onChange={ ( event ) => setPercent( event.target.value ) }
                            onBlur={calculate}
                        />
                    </Grid>
                </Grid>

                <Grid container className="row">
                    <Grid item xs={12} className="center result">
                        <p>
                            { error
                                ? <div className="error">{error}</div>
                                : '' }
                            { !error && dailyBudget
                                ? ( dailyBudget ? formatMoney( dailyBudget ) : '' )
                                : '' }
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
}
