import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import useAccounts from '../../hooks/useAccounts';

import useAuth from '../../hooks/useAuth';

export default function Logout() {
    const { setAuth } = useAuth();
    const { setAccount } = useAccounts();

    useEffect( () => {
        setAuth( null );
        setAccount( {} );
    }, [ setAccount, setAuth ] );

    return <Redirect to={{ pathname: '/' }} />;
}
