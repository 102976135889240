import FacebookAwarenessAds from './facebook/awareness/Ads';
import FacebookAwarenessAudiences from './facebook/awareness/Audiences';
import FacebookAwarenessCampaigns from './facebook/awareness/Campaigns';
import FacebookAwarenessCreatives from './facebook/awareness/Creatives';
import FacebookAwarenessSummary from './facebook/awareness/Summary';

// import FacebookBudgets from './facebook/Budgets';
// import FacebookComments from './facebook/Comments';

import FacebookEcomAds from './facebook/ecom/Ads';
import FacebookEcomAudiences from './facebook/ecom/Audiences';
import FacebookEcomCampaigns from './facebook/ecom/Campaigns';
import FacebookEcomCreatives from './facebook/ecom/Creatives';
import FacebookEcomSummary from './facebook/ecom/Summary';

import FacebookRetailerAds from './facebook/retailer/Ads';
import FacebookRetailerAudiences from './facebook/retailer/Audiences';
import FacebookRetailerCampaigns from './facebook/retailer/Campaigns';
import FacebookRetailerCreatives from './facebook/retailer/Creatives';
import FacebookRetailerSummary from './facebook/retailer/Summary';

import FacebookMediaPlan from '../shared/facebook/MediaPlan';
import FacebookPacing from '../shared/facebook/Pacing';

import { defaultRoutes } from '../shared';

const customRoutes = {
    'facebook/ads/awareness': FacebookAwarenessAds,
    'facebook/audiences/awareness': FacebookAwarenessAudiences,
    'facebook/campaigns/awareness': FacebookAwarenessCampaigns,
    'facebook/creatives/awareness': FacebookAwarenessCreatives,
    'facebook/summary/awareness': FacebookAwarenessSummary,
    
    'facebook/ads/ecom': FacebookEcomAds,
    'facebook/audiences/ecom': FacebookEcomAudiences,
    'facebook/campaigns/ecom': FacebookEcomCampaigns,
    'facebook/creatives/ecom': FacebookEcomCreatives,
    'facebook/summary/ecom': FacebookEcomSummary,
    
    'facebook/ads/retailer': FacebookRetailerAds,
    'facebook/audiences/retailer': FacebookRetailerAudiences,
    'facebook/campaigns/retailer': FacebookRetailerCampaigns,
    'facebook/creatives/retailer': FacebookRetailerCreatives,
    'facebook/summary/retailer': FacebookRetailerSummary,

    'facebook/media-plan': FacebookMediaPlan,
    'facebook/pacing': FacebookPacing
};

const routes = Object.assign(
    defaultRoutes.facebook,
    defaultRoutes.google,
    defaultRoutes.tiktok,
    customRoutes
);

export default routes;
