import { Alert } from '@material-ui/lab';
import { Button, Paper } from '@material-ui/core';
import { useState } from 'react';
import MaterialTable, { MTableActions, MTableBodyRow, MTableCell, MTableHeader, MTableToolbar } from '@material-table/core';

import useApi from '../../hooks/useApi';
import useExport from '../../hooks/useExport';
import useFormat from '../../hooks/useFormat';


// import MaterialTable, { MTableToolbar } from 'material-table';

// import FilterBuilder from './FilterBuilder';

// interface Column {
//     name: string;
//     type?: ColumnType;
//     // BUG: Any is included because array access is not allowed on string | ColumnType. This needs to be fixed.
//     [index:string] : string | ColumnType | any;
// }

interface Column {
    name: string;
    field: string;
    type?: string;
    precision?: number;
    render?: any;
}

interface ColumnSet {
    name: string;
    code: string;
    fields: Column[];
}

interface ColumnSetHash {
    [ code: string ]: ColumnSet;
}

interface CompareSettings {
    enabled: boolean;
    keys?: string[];
    showValue?: boolean;
}

interface MTColumn {
    title: string;
    field: string;
    type?: string;
    render?: ( row: any ) => JSX.Element;
    customFilterAndSearch?: any;
    editable?: string;
}

interface Props {
    loading?: boolean;
    data: any[];

    title?: string;
    className?: string;

    columns: Column[];

    freeze?: { [key: string]: number };

    render?: { [key: string]: ( row: any ) => JSX.Element };

    // Toolbar icons to add
    actions?: any[];

    rows?: number;
    rowOptions?: number[];

    enableDownload?: boolean;
    enableGroups?: boolean;
    enableHeader?: boolean;
    enableSearch?: boolean;
    enableSelect?: boolean;
    enableSort?: boolean;
    enableToolbar?: boolean;
    // Whether to show the column selection option
    enableColumnSelect?: boolean;

    actionsColumn?: number;

    edit?: any;

    emptyMessage?: string;

    compare?: CompareSettings;

    detailPanel?: ( row: any ) => JSX.Element;
}

function Table( props: Props ) {
    const { sendPost } = useApi();
    const { exportCsv } = useExport();
    const { formatType } = useFormat();

    const [ error, setError ] = useState('');
    const [ message, setMessage ] = useState('');

    const emptyMessage = props.emptyMessage ?? '...' ;

    // Auto-add toolbar if search or title set.
    const toolbar = ( props.enableToolbar || props.enableSearch || props.title ) ? true : false ;

    let data = props.data;

    // Convert column data into format for underlying table.
    let columns: MTColumn[] = [];

    // const inputColumns = Object.entries( props.columns );

    if ( props.columns.length ) {
        for ( let item of props.columns ) {
            let column: MTColumn = { title: item.name, field: item.field };

            // if ( props.types && props.types[ field ] ) {
            if ( item.type ) {
                // Pass column type for improving exports.
                column.type = item.type;

                column.render = ( row: any ) =>
                    // Show blank entry if row does not have field (ex: when
                    // columns are switched)
                    row[ item.field ] === undefined ? null
                        // Use its render if it has one.
                        : item.render ? item.render()
                            : formatType(
                                item.type ?? '',
                                row[ item.field ],
                                item.precision
                            );
            }

            // This should be passed in via Creatives.
            // if ( item.field === 'thumbnail' ) {
            //     column.render = ( row: any ) => {
            //         return <img src={row[ item.field]} alt='' />
            //     }
            // } else if ( item.field === 'preview_link' ) {
            //     column.render = ( row: any ) => {
            //         return <a href={row[ item.field ]} target='_blank'>Preview</a>
            //     }
            // }

            if ( props.render && props.render[ item.field ] ) {
                column.render = props.render[ item.field ];
            }

            if ( !columns.length ) {
                column.customFilterAndSearch = function( query: string, row: any ) {
                    // Lowercase to avoid case issues.

                    // Get each word of query.
                    const queries = query.toLowerCase().split( /\s+/ );

                    // Turn row into text.
                    const text = JSON.stringify( row ).toLowerCase();

                    // Check if each query matches.
                    for ( let q of queries ) {
                        if ( !text.match( q ) ) {
                            return false;
                        }
                    }

                    return true;
                }
            }

            columns.push( column );

            if ( props.compare?.keys?.length && props.compare?.keys.indexOf( item.field ) !== -1 ) {
                // Add diff.

                // Add to column list.
                let diffField = item.field + '_diff';

                let diffColumn = Object.assign(
                    {},
                    column,
                    {
                        title: column.title + ' Δ',
                        field: diffField,
                        // Use percent type with precision 1 for diffs.
                        render: ( row: any ) => formatType(
                            'percent',
                            row[ diffField ],
                            1
                        )
                    }
                );

                columns.push( diffColumn );

                // Add to data.
                for ( let i in data ) {
                    let oldValue = data[i].compare ? data[i].compare[ item.field ] : null;
                    let newValue = data[i][ item.field ];

                    let diff = 0;

                    // We must choose defaults to avoid NaN calculation results.

                    if ( newValue ) {
                        // If new value but no old value, default to 100% diff.
                        diff = oldValue ? ( newValue / oldValue - 1 ) * 100 : 100 ;
                    } else {
                        // If no new value but there is old value, default to -100% diff. If no old value too, default to 0%.
                        diff = oldValue ? -100 : 0 ;
                    }
                    
                    data[i][ diffField ] = diff;
                }

                // Add previous value.
                if ( props.compare.showValue ) {
                    let previousField = item.field + '_previous';

                    // Add to column list.
                    let previousColumn = Object.assign(
                        {},
                        column,
                        {
                            title: column.title + ' Prev',
                            field: previousField,
                            // Use same formatting as field.
                            render: ( row: any ) => formatType(
                                item.type ?? null,
                                row[ previousField ],
                                item.precision
                            )
                        }
                    );
    
                    columns.push( previousColumn );
    

                    // Add to data.
                    for ( let i in data ) {
                        data[i][ previousField ] = data[i].compare ? data[i].compare[ item.field ] : null;
                    }
                }
            }
        }
    }

    let classes = [ 'table' ];

    let componentProps: any = {};

    if ( props.detailPanel ) {
        // componentProps.detailPanel = props.detailPanel;
        componentProps.detailPanel = [ { render: props.detailPanel } ];

        classes.push( 'table-detail' );
    }

    if ( props.className ) {
        classes = classes.concat( props.className.split( /\s+/ ) );
    }

    // Check if it false but default to true if it's not set.
    if ( !(props.enableHeader ?? true ) ) {
        classes.push( 'table-no-header' );
    }

    let [ tableData, ] = useState( data );

    // const tableRef = useRef();

    const exportData = ( columns: any, data: any ) => {
        // Format data.

        // Create header from column names.
        const headerRow = columns.map( ( column: any ) => column.title.replace( 'Δ', 'Change' ) );

        const dataRows = data.map( ( item: any ) => {
            let row: any = [];

            for ( let i = 0; i < columns.length; i++ ) {
                let value = item[ columns[i].field ];

                let type = columns[i].type;

                if ( columns[i].field.match(/_diff$/) ) {
                    // Use percents for diffs.
                    value = formatType( 'percent', value, 2 );
                } else if ( columns[i].field.match(/_prev$/) ) {
                    // Compare previous values don't exist on the item and so must be handled differently.
                    value = formatType( item[ 'compare' ][ columns[i].field ], value, 2 );
                } else if ( type === 'percent' ) {
                    value = formatType( type, value, 2 );
                } else if ( type === 'money' ) {
                    value = formatType( type, value, 2 );
                }

                row.push( value );
            }

            return row;
        })

        const rows = [ headerRow, ...dataRows ];

        exportCsv( props.title ?? 'dashboard' , rows );
    };

    // const rowClick = ( row: any, event: any, togglePanel: any ) => {
    //     if ( props.detailPanel ) {
    //         togglePanel();
    //     }

    //     if ( row.link ) {
    //         window.open( row.link );
    //     }
    // }

    let editing = null;

    if ( props.edit ) {
        // Disable editing the wrong columns.
        for ( let i in columns ) {
            if (
                // If it isn't in the editable columns
                props.edit?.columnsEnabled?.indexOf( columns[ i ].field ) === -1
                // Or it is in the uneditable columns.
                || props.edit?.columnsDisabled?.indexOf( columns[ i ].field ) !== -1
            ) {
                columns[ i ].editable = 'never';
            }
        }

        editing = {
            onRowAdd: props.edit.onAdd,

            // Pass diff if updating.
            onRowUpdate: !props.edit.onUpdate ? null
                : ( newRow: any, oldRow: any ) => {
                    // See what was changed and only pass that.
                    let diff: any = {};

                    for ( let key of Object.keys( newRow ) ) {
                        if ( newRow[ key ] !== oldRow[ key ] ) {
                            diff[ key ] = newRow[ key ];
                        }
                    }

                    if ( !Object.keys(diff).length ) {
                        return new Promise( ( resolve: any, reject: any ) => {
                            setMessage( 'No changes made.' );
                            setError( '' );

                            resolve( 'No changes made.' );
                        } )
                    }

                    const { url, params, after } = props.edit.onUpdate( newRow, oldRow, diff );

                    return new Promise(
                        ( resolve: any, reject: any ) => {
                            // Clear display.
                            setError( '' );
                            setMessage( '' );

                            sendPost( url, params )
                            .then( ( response: any ) => {
                                setMessage( 'Changes saved.' );
                                setError( '' );

                                after( response );
                                resolve( response );
                            } )
                            .catch( err => {
                                setError( err.message );
                                setMessage( '' );
                                reject( err.message );
                            } );
                        }
                    )
                },
        };
    }

    // let filterFields: any = {};
    // for ( let i = 0; i < columns.length; i++ ) {
    //     filterFields[ columns[i].field ] = columns[i].title;
    // }

    // const showHeader 

    return <Paper className={ classes.join( ' ' ) }>
        { !error ? '' : <Alert severity="error">{error}</Alert> }
        { !message ? '' : <Alert severity="success">{message}</Alert> }

        <MaterialTable
            // tableRef={tableRef}
            title={ props.title ?? '' }

            // [ { title: 'Display Name', field: 'key', render: ( row: any ) => row.key } ]
            columns={columns}
            className="tablexxxxxx"
            // [ { field1: 'A', field2: 'B', link: 'www' } ]
            data={tableData}

            editable={editing}

            isLoading={ props.loading ?? false }
            options={{
                pageSize: props.rows ?? 10 ,
                pageSizeOptions: props.rowOptions ?? [] ,

                search: props.enableSearch ?? true ,
                selection: props.enableSelect ?? false ,
                sorting: props.enableSort ?? true ,
                toolbar: toolbar,

                // Put actions at end of row
                actionsColumnIndex: props.actionsColumn ?? null,

                grouping: props.enableGroups ?? false,

                fixedColumns: props.freeze ?? {} ,
                columnsButton: props.enableColumnSelect,

                exportMenu: 
                    props.enableDownload ? [ { label: 'Download', exportFunc: exportData } ] : []
                ,

                // Export all table data instead of just current page.
                exportAllData: true

                // tableLayout: 'fixed' fixed/auto width columns?
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: emptyMessage
                },
                pagination: {
                    labelRowsSelect: ''
                }
            }}
            actions={ props.actions }

            // // Override to hijack event handler and simplify calls
            // components={{
            //     Actions: props => {
            //         if ( props?.actions[0].onClick ) {
            //             console.log( 'GO' )
            //         }

            //         console.log(props)

            //       return <Button
            //       onClick={(event) => 
            //         console.log("add user", props)
            //       }}
            //     >
            //       Add New User
            //     </Button>

            //     }
            //   }}
    

            // onRowClick={ ( event, row, togglePanel ) => rowClick( row, event, togglePanel ) }

            {...componentProps}

            // Override using https://material-table.com/#/docs/features/component-overriding
            components={{
                // Actions: props => {
                //     // console.log( 'actions', props );
                //     let newProps = props;
                //     newProps.actions.unshift( newProps.actions.pop() );
                //     return <MTableActions className="ACTIONSxxx" {...newProps} />
                // },
                // Cell: props => {
                //     console.log( 'cell', props );
                //     return <MTableCell colSpan={0} rowSpan={1} xyz={123}  className="CELLxxx" {...props} />
                // },
                // Header: subprops => {
                //     // className doesnt work on MTableHeader.
                //     // Default to showing header.
                //     return <div className={ ( props.enableHeader ?? true ) ? '' : 'hidden' }>
                //         <MTableHeader {...subprops} />
                //     </div>
                // },
                // Row: props => {
                //     console.log( 'row', props );
                //     return <MTableBodyRow className="ROWxxx" {...props} />
                // }
                // Toolbar: props => {
                //     console.log( 'toolbar', props );
                //     return <div>
                //         123
                //         <MTableToolbar {...props} />
                //         456
                //     </div>;
                // }
            }}

            // What is columnsHiddenInColumnsButton?
            // components={{
            //     Toolbar: ( props: any ) => (
            //         <Grid container>
            //             <Grid item xs={6}>
            //                 {/* ( 1
            //                     ? <FilterBuilder
            //                         fields={filterFields}
            //                     />
            //                     : ''
            //                 ) */}
            //             </Grid>
            //             <Grid item xs={6}>
            //                 <MTableToolbar {...(Object.assign({columnsHiddenInColumnsButton: true},props))} />
            //             </Grid>
            //         </Grid>
            //     )
            // }}
        />
    </Paper>;
}

export type { Column, ColumnSet, ColumnSetHash };
export { Table };
