import { Grid, Icon } from "@material-ui/core";
import { useRef, useState } from "react";
import Alert from '@material-ui/lab/Alert';

import { ColumnSet, ColumnSetHash, Table } from "./Table";
import ColumnSelectPopover from "./ColumnSelectPopover";
import FilteredDatedContent from "./FilteredDatedContent";

interface Props {
    title: string;
    url: string;

    columnSets: ColumnSetHash;
    columnSet: string;

    dimensionParams?: Object;

    enableCompare?: boolean;
    enableDates?: boolean;
    enableDownload?: boolean;
    wrap?: boolean;
}

export default function ColumnSelectTable( props: Props ) {
    const [ columnSet, setColumnSet ] = useState( props.columnSet );

    const popoverAnchor: any = useRef();
    const [ popoverOpen, setPopoverOpen ] = useState( false );

    if ( !props.columnSets[ columnSet ] ) {
        return <Alert severity="error">
            Column set {columnSet} not found in: {Object.keys(props.columnSets).join(', ')}
        </Alert>
    }

    const requestFields = props.columnSets[ columnSet ].fields.map( (item) => item.field ).join( ',' );

    let actions: any = [];

    if ( Object.keys( props.columnSets ?? {} ).length > 1 ) {
        actions.push({
            icon: () => <Icon ref={popoverAnchor}>view_column</Icon>,
            tooltip: 'Select Columns',
            isFreeAction: true,
            // iconProps: {
            //     classes: 'xxxxx'
            // },
            onClick: (event: any) => {
                setPopoverOpen( !popoverOpen );
            }
        });
    }

    const render = ( data: any, options: any ) => {
        // console.log( 'tableau -> ', data?.list );
        return <Grid container justifyContent="center" className="column-select">
            <ColumnSelectPopover
                anchor={popoverAnchor}

                open={popoverOpen}
                onClose={ () => {
                    setPopoverOpen( !popoverOpen )
                } }
                onChange={ ( value: string ) => {
                    setPopoverOpen( !popoverOpen );
                    setColumnSet( value );
                } }

                columns={Object.values( props.columnSets )}
                value={columnSet}
            />

            <Grid item xs={12}>
                <Table
                    // title={props.title}

                    className={ props.wrap ? 'wrap' : '' }

                    columns={ props.columnSets[ columnSet ].fields }
                    data={data?.list ?? []}

                    rows={ Math.min( data?.list?.length ? data.list.length : 20, 20 )}
                    enableSearch={true}
                    enableDownload={ props.enableDownload ?? true }

                    actions={actions}

                    compare={{
                        enabled: options?.compare?.enabled,
                        keys: options?.compare?.keys,
                        showValue: options?.compare?.showValue
                    }}

                    // detailPanel={row => <div className="test-detail" dangerouslySetInnerHTML={ { '__html': row.campaign_info } }></div> }
                />
            </Grid>
        </Grid>
    }

    return <FilteredDatedContent
        title={props.title}
        url={props.url}
        params={{fields: requestFields}}
        dimensionParams={props.dimensionParams}

        enableCompare={ props.enableCompare ?? false }
        enableDates={props.enableDates}

        render={render}
    />
}
