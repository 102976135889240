import { useEffect, useState } from 'react';
import { createStore } from 'reusable';

import useAuth from './useAuth';
import useDate from './useDate';
import useRouter from './useRouter';
// import useSession from './useSession';

const keyExists = ( object: any, key: string ): boolean => {
    // We can't check if just the value is undefined because a key can have an undefined value. We need to make sure the key doesn't exist.
    return Object.keys( object ).indexOf( key ) !== -1;
}

const useFilters = createStore( () => {
    const { history, location, params, setParams } = useRouter();
    const { getDate } = useDate();

    const { auth } = useAuth();
    // const { setSession } = useSession();

    // Load filters from session.
    // //console.log( session );
    // const [ filters, setAllFilters ]: any = useState( session[ 'filters' ] ?? {} );
    const [ filters, setAllFilters ]: any = useState( {} );

    const clearFilters = () => {
        // Avoid state update if data stays the same.
        // Two empty objects are not equal so we have to check the number of keys.
        if ( Object.keys( filters ).length === 0 ) {
            return;
        }

        // setSession( 'filters', {} );
        setFilters({});
    }

    // Merge into object instead of having a variable for each filter.
    // Filters use underscore case because they get directly translated to URL parameters.
    const setFilter = ( key: string, value: any ) => {
// console.log( 'set', key, '=', value )

        const data = { [key]: value };
// console.log(123);
        setFilters( data );
// console.log(456);
// console.log( 'XXX', filters );
        // setAllFilters( ( previous: any ) => {
        //     // Avoid state update if data stays the same.
        //     if ( previous[ key ] === value ) {
        //         return previous;
        //     }

        //     // Merge value into current data.
        //     const filterData = { ...previous, [key]: value };

        //     // Update URL.
        //     setParam( key, value );

        //     // if ( value === undefined ) {
        //     //     delete filterData[ key ];
        //     // }
        //     // setSession( 'filters', filterData, true );

        //     return filterData;
        // } );
    };

    const setFilters = ( data: { [ key: string ]: any } ) => {
        setAllFilters( ( previous: any ) => {
// console.log( 'setall', data )
            // Avoid state update if data stays the same.
            // If all values match, do nothing.
            let matchCount = 0;

            for ( let key in data ) {
                if ( previous[ key ] === data[ key ] ) {
                    matchCount++;
                }
            }

            if ( matchCount === Object.keys( data ).length ) {
// console.log( 'filters same', data, "\nvs ", previous)
                return previous;
            }

            const filterData: any = {...previous, ...data};

            // setSession( 'filters', filterData, true );

            return filterData;
        } );
    }

    const unsetFilter = ( key: string ) => {
        setAllFilters( ( previous: any ) => {
            // Avoid state update if data stays the same.
            if ( !keyExists( previous, key ) ) {
                return previous;
            }

            delete previous[ key ];

            // setSession( 'filters', previous, true );

            return previous;
        } );
    };

    const unsetFilters = ( keys: string[] ) => {
        setAllFilters( ( previous: any ) => {
            // Avoid state update if data stays the same.
            // If all keys are missing, do nothing.
            let matchCount = 0;

            for ( let key of keys ) {
                if ( !keyExists( previous, key ) ) {
                    matchCount++;
                }
            }

            if ( matchCount === Object.keys( keys ).length ) {
                return previous;
            }

            for ( let key of keys ) {
                delete previous[ key ];
            }

            // setSession( 'filters', previous, true );

            return previous;
        } );
    };

    useEffect( () => {
// console.log( 'useeffect', auth.level, location, filters)
// console.log( 'effect filters', filters, "\nvs", params, "\n", location, "\n\n\n" );
        // If there are params set in the URL, make them override everything else.
        // if ( Object.keys( params ).length ) {
        //     setFilters( params );
        //     return;
        // }

        const now: number = Date.now();

        let newData: { [ key: string ]: string } = {};

        if ( !filters.date_start ) {
            newData[ 'date_start' ] = getDate( 'Y-m-d', new Date( now - 30 * 86400 * 1000 ) );
            // setFilter( 'date_start', getDate( 'Y-m-d', new Date( now - 30 * 86400 * 1000 ) ) );
        }

        if ( !filters.date_end ) {
            newData[ 'date_end' ] = getDate( 'Y-m-d', new Date( now - 86400 * 1000 ) );
            // setFilter( 'date_end', getDate( 'Y-m-d', new Date( now - 86400 * 1000 ) ) );
        }

        if ( !filters.view ) {
            setFilter( 'view', auth.level );
        }

        // TODO: WHY is this even there?
        // if ( auth.level !== filters[ 'view' ] ) {
        //     newData[ 'view' ] = auth.level;
        // }
        // setFilter( 'view', auth.level );

        if ( Object.keys( newData ).length ) {
            setFilters( newData );
        }
    // }, [ auth.level, filters.date_start, filters.date_end, location ] );
    }, [ auth.level, filters, location ] );

    return {
        filters,
        setFilter,
        setFilters,
        unsetFilter,
        unsetFilters,
        clearFilters
    };
} );

export default useFilters;
