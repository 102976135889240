
import ComingSoon from './components/ComingSoon';
import Home from './components/Home';

import AccountsForgot from './components/accounts/Forgot';
import AccountsLogin from './components/accounts/Login';
import AccountsLogout from './components/accounts/Logout';
import AccountsReset from './components/accounts/Reset';

import AdminSystemCache from './components/admin/system/Cache';
import AdminSystemStatus from './components/admin/system/Status';
import AdminToolsBudgetCalculator from './components/admin/tools/BudgetCalculator';
import AdminToolsPacing from './components/admin/tools/Pacing';
import AdminUsersList from './components/admin/users/List';

// import AdminBudgetsMinSpends from './_old/admin/budgets/MinSpends';
// import AdminBudgetsPacing from './_old/admin/budgets/Pacing';
// import AdminHealthFacebookCatalog from './_old/admin/health/FacebookCatalog';
// import AdminHealthFacebookCTAs from './_old/admin/health/FacebookCTAs';
// import AdminHealthFacebookLinkParams from './_old/admin/health/FacebookLinkParams';
// import AdminHealthFacebookPixel from './_old/admin/health/FacebookPixel';
// import AdminSetupAudiences from './_old/admin/setup/Audiences';

import Test from './components/Test';

const routes = [
    // Login

    {
        path: '/account/forgot',
        component: AccountsForgot,
        loggedIn: false
    },
    {
        path: '/account/reset/:code',
        component: AccountsReset,
        loggedIn: false
    },
    /* <AuthRoute component={AccountsSetup} path="/account/setup" loggedIn={false} /> */
    {
        path: '/login',
        component: AccountsLogin,
        loggedIn: false
    },
    {
        path: '/logout',
        component: AccountsLogout
    },

    /* <AuthRoute component={AdminHealthFacebookCatalog} path="/admin/health/facebook/catalog" />
    <AuthRoute component={AdminHealthFacebookCTAs} path="/admin/health/facebook/ctas" />
    <AuthRoute component={AdminHealthFacebookPixel} path="/admin/health/facebook/pixel" />
    <AuthRoute component={AdminHealthFacebookLinkParams} path="/admin/health/facebook/link-params" />

    <AuthRoute component={AdminBudgetsMinSpends} path="/admin/facebook/budgets/min-spends" />
    <AuthRoute component={AdminBudgetsPacing} path="/admin/facebook/budgets/pacing" />
    <AuthRoute component={AdminSetupAudiences} path="/admin/facebook/setup/audiences" /> */

    {
        path: '/admin/system/cache',
        component: AdminSystemCache
    },
    {
        path: '/admin/system/status',
        component: AdminSystemStatus
    },
    {
        path: '/admin/tools/budget-calculator',
        component: AdminToolsBudgetCalculator
    },
    {
        path: '/admin/tools/pacing',
        component: AdminToolsPacing
    },
    {
        path: '/admin/users',
        component: AdminUsersList
    },

    {
        path: '/mtest',
        component: Test
    },

    {
        path: '/',
        component: Home,
        exact: true
    },

    // Fallback

    {
        path: '/',
        component: ComingSoon
    }
];

export default routes;