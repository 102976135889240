import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

interface Props {
    component: any;
    exact?: boolean;
    loggedIn?: boolean;
    path: string;
}

export default function AuthRoute( props: Props ) {
    const { isExpired, isLoggedIn, setAuth } = useAuth();

    // If logged in but it's expired, force a logout.
    if ( isLoggedIn && isExpired && props.path !== '/logout' ) {
        return <Redirect to="/logout" />;
    }

    // Default to requiring authentication.
    let loggedIn = props.loggedIn ?? true;

    if ( loggedIn && !isLoggedIn ) {
        return <Redirect to="/login" />;
    }

    if ( !loggedIn && isLoggedIn ) {
        return <Redirect to="/" />;
    }

    return <Route {...props} />;
}
