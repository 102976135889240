import { Grid, Icon, IconButton, Paper, Tooltip } from '@material-ui/core';
import React, { Fragment, useState } from 'react';

import useAccounts from '../../hooks/useAccounts';
import useAuth from '../../hooks/useAuth';
import useDate, { DateRange } from '../../hooks/useDate';
import useFilters from '../../hooks/useFilters';
import useQuery from '../../hooks/useQuery';
import useQueryDimensions from '../../hooks/useQueryDimensions';
import useScreenshot from '../../hooks/useScreenshot';

import DateRangePicker from './DateRangePicker';
import LoadedContent from './LoadedContent';
import Select from './Select';

interface Props {
    title: string;
    url: string;

    params?: Object;

    enableCompare?: boolean;
    enableDates?: boolean;
    enableSave?: boolean;

    renderContent: (data: any, options: any) => JSX.Element;
    renderTopbar?: (data: any, options: any) => JSX.Element;

    topbarLoaded?: boolean;
    topbarError?: string|null;
    topbarRefresh?: any;

    children?: React.ReactNode;
}

export default function DatedContent( props: Props ) {
    const { account } = useAccounts();
    const { canView } = useAuth();
    const { addDays, getDate, getDifferenceInDays } = useDate();
    const { filters, setFilter, setFilters } = useFilters();
    const { saveImage } = useScreenshot();

    const [ compareEnabled, setCompareEnabled ] = useState(false);
    const [ compareShowValue, setCompareShowValue ] = useState(false);

    let params: any = props.params ?? {} ;

    const { data, refresh, error: queryError, isLoaded: queryLoaded } = useQuery( props.url, params );

    const reload = () =>  {
        refresh();

        if ( props.topbarRefresh ) {
            props.topbarRefresh();
        }
    }

    const setDateRange = ( range: DateRange ) => {
        setFilters({
            date_start: range.start,
            date_end: range.end
        });
    }

    const setCompareDateRange = ( range: DateRange ) => {
        setFilters({
            compare_date_start: range.start,
            compare_date_end: range.end
        });
    }

    const toggleCompareEnabled = () => {
        // const date = new DateFns();

        // If we're turning on, dyamically set the previous period.
        if ( !compareEnabled ) {
            // Get current period length to mirror.
            const length = getDifferenceInDays( filters[ 'date_start' ], filters[ 'date_end' ] );
            
            const range: DateRange = {
                start: getDate( 'Y-m-d', addDays( filters[ 'date_start' ], -1 * length - 1 ) ),
                end: getDate( 'Y-m-d', addDays( filters[ 'date_start' ], -1 ) )
            };

            setCompareDateRange( range );
            // setFilters({
            //     compare_date_start: getDate( 'Y-m-d', start ),
            //     compare_date_end: getDate( 'Y-m-d', end ),
            // });
        }

        setCompareEnabled( !compareEnabled );
    }

    const toggleCompareShowValue = () => setCompareShowValue( !compareShowValue );

    // Show compare when it's enabled and we have compare data. We have compare
    // data if the compare key exists on the response. This avoids flashing the
    // compare UI with empty data when the UI option is checked but no data is
    // loaded yet.

    let hasCompareData = data?.compare ? true : false ;

    const options = {
        compare: {
            enabled: compareEnabled && hasCompareData,
            keys: data?.compare?.keys,
            // Don't show value if compare is not enabled.
            showValue: compareEnabled && compareShowValue && hasCompareData
        }
    };

    const getImageName = () => {
        let name = 
            account.name
            + ' ' + props.title
            + ' ' + document.querySelector('.MuiTab-root.Mui-selected .MuiTab-wrapper')?.textContent
            + ' ' + getDate( 'Y-m-d', filters.date_start )
            + ' to ' + getDate( 'Y-m-d', filters.date_end )
            // Must use compare from options because it detects if we loaded compare data. Filters will show if the compare toggle is on which is not the same.
            + ( options.compare.enabled ? ' vs ' + getDate( 'Y-m-d', filters.compare_date_start ) + ' to ' + getDate( 'Y-m-d', filters.compare_date_end ) : '' )
            ;
        const allowed = [ 'area', 'funnel', 'store', 'objective', 'request' ];

        for ( let key in filters ) {
            if ( allowed.indexOf( key ) !== -1 ) {
                name += ' ' + filters[key];
            }
        }

        return name;
    }

    // Default to show dates.
    const showDates = props.enableDates ?? true ;

    return <LoadedContent
        loaded={ queryLoaded && ( props.topbarLoaded ?? true ) }
        error={queryError || ( props.topbarError ?? '' ) }
    >
        <h1>{account.name}</h1>

        <h2>{props.title}</h2>

        <Grid container className="filters" justifyContent="space-between">
            <Grid item className="dropdowns">
                { props.renderTopbar ? props.renderTopbar( data, options ) : '' }
            </Grid>
            <Grid item className="date-select">
                <Paper className="date-control">
                    { showDates
                        ? <Fragment>
                            <DateRangePicker start={filters.date_start} end={filters.date_end} onChange={setDateRange} />

                            { props.enableCompare
                                ? <IconButton className={ compareEnabled ? "toolbar enabled" : "toolbar" } onClick={toggleCompareEnabled}>
                                    <Tooltip title="Compare">
                                        <Icon>swap_horiz</Icon>
                                    </Tooltip>
                                </IconButton>
                                : ''
                            }
                        </Fragment>
                        : ''
                    }

                    { props.enableSave && canView( filters.view, 'user' )
                        ? <IconButton className="toolbar" onClick={() => saveImage('.tab-content .content', getImageName ) }>
                            <Icon>photo</Icon>
                        </IconButton>
                        : ''
                    }

                    <IconButton className="toolbar" onClick={reload}>
                        <Tooltip title="Refresh">
                            <Icon>refresh</Icon>
                        </Tooltip>
                    </IconButton>

                    { props.enableCompare && compareEnabled && filters.compare_date_start
                        ? <div className="compare">
                            <DateRangePicker start={filters.compare_date_start} end={filters.compare_date_end} onChange={setCompareDateRange} />
                            <IconButton className={ compareShowValue ? "toolbar enabled" : "toolbar" } onClick={toggleCompareShowValue}>
                                <Tooltip title="Show Previous">
                                    <Icon>looks_two</Icon>
                                </Tooltip>
                            </IconButton>
                        </div>
                        : ''
                    }
                </Paper>
            </Grid>
        </Grid>

        {props.children}

        { props.renderContent ? props.renderContent( data, options ) : '' }
    </LoadedContent>;
}
