import { TabCards } from '../../../components/ui/TabCards';
import useFields, { Column } from '../../../hooks/useFields';

export default function Summary() {
    const { getLayout } = useFields();

    const tabs: any = [
        {
            name: 'Overview',
            cards: addPrefix(
                'all',
                getLayout( [
                    [ 'spend', 'impressions' ],
                    [ 'clicks', 'ctr', 'cpc' ],
                    [ 'purchases_time', 'purchase_rate_time', 'aov_time' ],
                    [ 'revenue_time',  'roas_time_float' ],
                ] )
            )
        },
        {
            name: 'Search',
            cards: addPrefix(
                'search',
                getLayout( [
                    [ 'spend', 'impressions' ],
                    [ 'clicks', 'ctr', 'cpc' ],
                    [ 'purchases_time', 'purchase_rate_time', 'aov_time' ],
                    [ 'revenue_time',  'roas_time_float' ],
                    // [ 'click_share', 'impression_share' ]
                ] )
            )
        },
        {
            name: 'Shopping',
            cards: addPrefix(
                'shopping',
                getLayout( [
                    [ 'spend', 'impressions' ],
                    [ 'clicks', 'ctr', 'cpc' ],
                    [ 'purchases_time', 'purchase_rate_time', 'aov_time' ],
                    [ 'revenue_time',  'roas_time_float' ],
                ] )
            )
        },
        {
            name: 'Display',
            cards: addPrefix(
                'display',
                getLayout( [
                    [ 'spend', 'impressions' ],
                    [ 'clicks', 'ctr', 'cpc' ],
                    [ 'purchases_time', 'purchase_rate_time', 'aov_time' ],
                    [ 'revenue_time',  'roas_time_float' ],
                ] )
            )
        },
        {
            name: 'Youtube',
            cards: addPrefix(
                'youtube',
                getLayout( [
                    [ 'spend', 'impressions' ],
                    [ 'clicks', 'ctr', 'cpc' ],
                    [ 'purchases_time', 'purchase_rate_time', 'aov_time' ],
                    [ 'revenue_time',  'roas_time_float' ],
                ] )
            )
        },
    ];

    return <TabCards
        title='Google Summary'
        api='google/summary'
        dimensionParams={{ platform: 'google' }}
        tabs={tabs}
        enableCompare={true}
        enableSave={true}
    />
}

const addPrefix = ( prefix: string, fields: Column[][] ): any[][] => {
    let copy: Column[][] = JSON.parse( JSON.stringify( fields ) );

    for ( let i in copy ) {
        for ( let j in copy[ i ] ) {
            copy[ i ][ j ].field = prefix + '.' + copy[ i ][ j ].field;
        }
    }

    return copy;
}
