import useFields from '../../../../hooks/useFields';
import ColumnSelectTable from '../../../../components/ui/ColumnSelectTable';

export default function Audiences() {
    const { getFields, removeFields } = useFields();

    const fieldSets = removeFields(
        [ /^ad_/, /^campaign_status/ ],
        getFields( [
            'fb_setup_adgroup',
            'fb_ecom', 'fb_ecom1', 'fb_ecom_funnel', 'fb_ecom_funnel2', 'fb_ecom_funnel3'
        ] )
    );

    const set = 'fb_ecom';

    return <ColumnSelectTable
        title='Facebook Ecom Audiences'
        url='facebook/audiences/ecom'
        dimensionParams={{ platform: 'facebook', type: 'ecom' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
    />
}
