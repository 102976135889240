import ChannelCreatives from '../../../components/channels/Creatives';
import useFields from '../../../hooks/useFields';

export default function Creatives() {
    const { getFields, removeFields } = useFields();

    const sets = removeFields(
        [
            'campaign_name', 'adgroup_name', 'ad_name',
            'campaign_status_effective', 'adgroup_status_effective',
            'ad_thumbnail'
        ],
        getFields( [ 'fb_ecom', 'fb_ecom1', 'fb_ecom_funnel', 'fb_ecom_funnel2', 'fb_ecom_funnel3' ] )
    );

    const set = 'fb_ecom';

    return <ChannelCreatives
        title='Facebook Creatives'
        url='facebook/creatives'
        dimensionParams={{ platform: 'facebook' }}
        columnSets={sets}
        columnSet={set}
        defaultSort='Revenue'
    />
}
