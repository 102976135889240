import { useState } from "react";
import DatedContent from "../../../components/ui/DatedContent";
import LoadedContent from "../../../components/ui/LoadedContent";
import Select from "../../../components/ui/Select";
import { Table } from "../../../components/ui/Table";
import useAccounts from "../../../hooks/useAccounts";
import useQuery from "../../../hooks/useQuery";
import useQueryDimensions from "../../../hooks/useQueryDimensions";

export default function MediaPlan() {
    let { dimensions, dimensionsRefresh, dimensionsError, dimensionsLoaded } = useQueryDimensions();

    const fieldsOrder = useState([]);

    const renderContent = ( data: any, options: any ) => {
        const columnNames = data?.list?.length ? Object.keys( data.list[0] ) : [] ;

        let columns = [];
    
        for ( let name of columnNames ) {
            columns.push({
                name: name,
                field: name,
                type: data?.columns[ name ]?.type ?? null,
                precision: data?.columns[ name ]?.precision ?? null
            });
        }

        return <div className="page-media-plan table-no-header">
            <Table
                columns={columns}
                data={ data?.list }
                rows={ data?.list?.length ?? 5 }

                enableHeader={false}
            />
        </div>;
    }

    const renderTopbar = ( data: any, options: any ) => {
        return <></>
        // return fieldsOrder.map(
        //     ( field: string, i:number ) => <Select
        //         id={field}
        //         text={i}
        //         items={ dimensions.map(
        //             ( dimension: any ) => {
        //                 return { text: dimension.name, value: dimension.code }
        //             }
        //         ) }
        //         // value={}
        //     />
        // );
    }

    return <DatedContent
        title="Media Plan"
        url="facebook/media-plan"
        renderContent={renderContent}

        topbarLoaded={dimensionsLoaded}
        topbarError={dimensionsError}
        topbarRefresh={dimensionsRefresh}
        renderTopbar={renderTopbar}
    />;
}
