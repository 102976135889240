import useQuery from "./useQuery";

export default function useQueryDimensions( params: any = {} ) {
    const {
        data: dimensions,
        error: dimensionsError,
        isLoaded: dimensionsLoaded,
        refresh: dimensionsRefresh
    } = useQuery( '/account/dimensions', params );


    return { dimensions, dimensionsError, dimensionsLoaded, dimensionsRefresh };
}
