import axios from 'axios';

const login = ( username: string, password: string ): Promise<any> => {
    let params = { username, password };

    return sendRequest( 'post', 'login', {}, params );
}

const sendGet = ( url: string, params: any = {} ): Promise<any> => {
    return sendRequest( 'get', url, params );
}

const sendPost = ( url: string, data: any = {} ): Promise<any> => {
    return sendRequest( 'post', url, {}, data );
}

const sendRequest = ( type: any, path: string, params: Object = {}, data: any = {} ): Promise<any> => {
    let query = {};

    if ( Object.keys( params ).length ) {
        query = { ...query, ...params };
    }

    let options: any = {
        method: type,
        url: path,
        params: query
    };

    if ( Object.keys( data ).length ) {
        let form = new FormData();

        for( let key in data ) {
            // Auto convert objects since only strings allowed.
            let value = typeof( data[ key ] ) === 'object' ? JSON.stringify( data[ key ] ) : data[ key ] ;

            form.set( key, value );
        }

        options.data = form;
    }

    const request = axios( options )
    .then( (response: any) => {
        if ( response.data.status === 'success' ) {
            return response.data.data;
        } else {
            let message = response.data.message;
            throw new Error( message );
        }
    });

    return request;
}

export default function useApi() {
    return {
        login,
        sendGet,
        sendPost,
        sendRequest
    };
}
