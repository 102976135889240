import { defaultMenus } from '../shared';

const menus = [
    {
        name: 'Facebook',
        icon_svg: 'facebook',
        platform: 'facebook',
        // permission: 'facebook',
        children: [
            {
                name: 'Ecommerce',
                icon: 'wifi',
                children: [
                    {
                        name: 'Summary',
                        icon: 'format_list_bulleted',
                        link: 'facebook/summary/ecom',
                    },
                    {
                        name: 'Campaigns',
                        icon: 'opacity',
                        link: 'facebook/campaigns/ecom'
                    },
                    {
                        name: 'Audiences',
                        icon: 'people',
                        link: 'facebook/audiences/ecom',
                        view: 'user'
                    },
                    {
                        name: 'Ads',
                        icon: 'crop_original',
                        link: 'facebook/ads/ecom',
                        view: 'user'
                    },
                    {
                        name: 'Creatives',
                        icon: 'visibility',
                        link: 'facebook/creatives/ecom',
                    },
                ]
            },

            {
                name: 'Awareness',
                icon: 'favorite',
                children: [
                    {
                        name: 'Summary',
                        icon: 'format_list_bulleted',
                        link: 'facebook/summary/awareness',
                    },
                    {
                        name: 'Campaigns',
                        icon: 'opacity',
                        link: 'facebook/campaigns/awareness'
                    },
                    {
                        name: 'Audiences',
                        icon: 'people',
                        link: 'facebook/audiences/awareness',
                        view: 'user'
                    },
                    {
                        name: 'Ads',
                        icon: 'crop_original',
                        link: 'facebook/ads/awareness',
                        view: 'user'
                    },
                    {
                        name: 'Creatives',
                        icon: 'visibility',
                        link: 'facebook/creatives/awareness',
                    },
                ]
            },

            {
                name: 'Retailer',
                icon: 'store',
                children: [
                    {
                        name: 'Summary',
                        icon: 'format_list_bulleted',
                        link: 'facebook/summary/retailer',
                    },
                    {
                        name: 'Campaigns',
                        icon: 'opacity',
                        link: 'facebook/campaigns/retailer'
                    },
                    {
                        name: 'Audiences',
                        icon: 'people',
                        link: 'facebook/audiences/retailer',
                        view: 'user'
                    },
                    {
                        name: 'Ads',
                        icon: 'crop_original',
                        link: 'facebook/ads/retailer',
                        view: 'user'
                    },
                    {
                        name: 'Creatives',
                        icon: 'visibility',
                        link: 'facebook/creatives/retailer',
                    },
                ]
            },

            {
                name: 'All',
                icon: 'pie_chart',
                children: [
                    {
                        name: 'Summary',
                        icon: 'format_list_bulleted',
                        link: 'facebook/summary',
                    },
                    {
                        name: 'Campaigns',
                        icon: 'opacity',
                        link: 'facebook/campaigns'
                    },
                    {
                        name: 'Audiences',
                        icon: 'people',
                        link: 'facebook/audiences',
                        view: 'user'
                    },
                    {
                        name: 'Ads',
                        icon: 'crop_original',
                        link: 'facebook/ads',
                        view: 'user'
                    },
                    {
                        name: 'Creatives',
                        icon: 'visibility',
                        link: 'facebook/creatives',
                    },
                ]
            },

            {
                name: 'Media Plan',
                icon: 'today',
                link: 'facebook/media-plan',
                view: 'admin'
            },
            {
                name: 'Pacing',
                icon: 'speed',
                link: 'facebook/pacing',
                view: 'user'
            },

            {
                name: 'Health',
                icon: 'local_hospital',
                view: 'admin',
                children: [
                    {
                        name: 'Catalog',
                        icon: 'menu_book',
                        link: 'facebook/health/catalog'
                    },
                    {
                        name: 'Pixel',
                        icon: 'adjust',
                        link: 'facebook/health/pixel'
                    },
                    {
                        name: 'Link Params',
                        icon: 'link',
                        link: 'facebook/health/link-params'
                    },
                    {
                        name: 'CTAs',
                        icon: 'call_to_action',
                        link: 'facebook/health/ctas'
                    },
                    // {
                    //     name: 'Descriptions',
                    //     icon: 'text_format',
                    //     link: 'facebook/health/descriptions',
                    //     disabled: true
                    // }
                ]
            }

            // {
            //     name: 'Budgets',
            //     icon: 'monetization_on',
            //     link: 'facebook/budgets'
            // },
            // {
            //     name: 'Comments',
            //     icon: 'forum',
            //     link: 'facebook/comments',
            //     view: 'admin'
            //     // permission: 'comments',
            // },
        ]
    },

    defaultMenus.google,

    defaultMenus.tiktok,

    // , defaultMenus.ga,
    // defaultMenus.shopify,
    // defaultMenus.magento,
    // defaultMenus.klaviyo,
    // defaultMenus.snapchat, defaultMenus.pinterest

    // {},
    // {
    //     name: 'Tools',
    //     icon: 'build',
    //     view: 'client_user',
    //     children: [
    //         {
    //             name: 'UTM Parser',
    //             icon: 'find_replace',
    //             view: 'client_user',
    //             link: 'tools/utm-parser'
    //         },
    //     ]
    // },

];

export default menus;
