import { createStore } from 'reusable';
import { useEffect, useState } from 'react';

import useApi from './useApi';
import useAuth from './useAuth';
import useSession from './useSession';

const useAccounts = createStore( () => {
    const { sendGet } = useApi();
    const { auth } = useAuth();

    const { getSession, setSession } = useSession();

    const [ accounts, setAccounts ]: any = useState([]);
    const [ account, setAccountRaw ]: any = useState({});

    const [ error, setError ] = useState('');
    const [ loaded, setLoaded ] = useState(false);

    const setAccount = ( account: any ) => {
        setSession( 'account', account[ 'code' ] );
        setAccountRaw( account );
    }

    useEffect( () => {
        (async () => {
            // Don't set if we already loaded
            // if ( loaded ) {
            //     return;
            // }

            try {
                if ( !auth.id ) {
                    setAccounts([]);
                    setLoaded( true );
                    return;
                }

                let data = await sendGet( 'accounts', { u: auth.id } );

                setAccounts( data );

                // Auto select the saved account when loading.
                const savedAccount = getSession( 'account' );

                if ( savedAccount ) {
                    // Find in the list.
                    const list = data.filter( ( item: any ) => item[ 'code' ] === savedAccount );

                    if ( list.length ) {

                        setAccount( list[0] );
                        setLoaded( true );
                        return;
                    }
                }

                // If we made it here, there is no saved account or it's not in the returned list.

                // Default to the first account. This is required for lists of
                // 1 account because we don't show the selection UI when there's
                // only one to choose from.
                if ( data.length ) {
                    setAccount( data[0] );
                    setLoaded( true );
                }
            } catch ( error: any ) {
                setError( `Unable to load accounts: ${error.message}` );
                setLoaded( true );
            }
        })();
    }, [ auth.id ]);
    // }, [ loaded, auth.id ]);

    return {
        accounts,
        account,
        setAccount,
        loaded,
        error
    };
} );

export default useAccounts;
