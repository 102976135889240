import { FormControl, InputLabel, MenuItem, Select as MUISelect } from '@material-ui/core';
import React from 'react';

interface Props {
    // Unique identifier to distinguish Select from others on page.
    id: string;
    text?: string;
    value: any;

    open?: boolean;

    items: Item[];
    columns?: any;

    showAll?: boolean;
    onChange: Function;
    onClose?: Function;

    className?: string;
}

interface Item {
    text: string;
    value: string;
}

export default function Select( props: Props ) {
    // Hide if no items.
    if ( !props.items?.length ) {
        return null;
    }

    // Columns are mappings of which fields should be used for the display text
    // and internal value.
    const columns = props.columns ?? { text: 'text', value: 'value' };

    let params: any = {
        // label: 'select-' + ( props.id ?? props.text?.toLowerCase().replace( /\s+/, '-' ) ),

        // Default to 'all' if no value set and showAll is true.
        value: props.value ?? ( props.showAll ? 'all' : '' )
    }

    // Keep open for column select dropdowns.
    if ( props.open ) {
        params.open = props.open;
    }

    // Add back from destructuring.
    // // Default to 'all' if no value set and showAll is ture.
    // let value = props.value ? props.value : ( props.showAll ? 'all' : '' );

    // Call onChange with this ID and the value.
    if ( props.onChange ) {
        params.onChange = (event: any) => props.onChange( props.id, event.target.value )
    }


    if ( props.onClose ) {
        params.onClose = props.onClose;
    }

    // What was ID for? InputLabel id={params.label}
    return <FormControl className={ 'select ' + ( props.className ?? '' ) }>
        { props.text ? <InputLabel>{props.text}</InputLabel> : '' }
        <MUISelect {...params}>
            { props.showAll ? <MenuItem value='all'>All</MenuItem> : '' }
            { props.items.map( (item: any) =>
                <MenuItem key={item[ columns.value ]} value={item[ columns.value ]}>
                    {item[ columns.text ]}
                </MenuItem>
            ) }
        </MUISelect>
    </FormControl>;
};
