import ColumnSelectTable from '../../../../components/ui/ColumnSelectTable';
import useFields from '../../../../hooks/useFields';

export default function Ads() {
    const { getFields, removeFields } = useFields();

    const fieldSets = removeFields(
        [ /^(adgroup|campaign)?_status/ ],
        getFields( [
            'fb_setup_ad', 'fb_creative',
            'fb_ecom', 'fb_ecom1', 'fb_ecom_funnel', 'fb_ecom_funnel2', 'fb_ecom_funnel3'
        ] )
    );

    const set = 'fb_ecom';

    return <ColumnSelectTable
        title='Facebook Ecom Ads'
        url='facebook/ads/ecom'
        dimensionParams={{ platform: 'facebook', type: 'ecom' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
    />
}
