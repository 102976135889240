import useFields from '../../../../hooks/useFields';
import ColumnSelectTable from '../../../../components/ui/ColumnSelectTable';

export default function Audiences() {
    const { getFields, removeFields } = useFields();

    const fieldSets = removeFields(
        [ /^ad_/, /^campaign_status/ ],
        getFields( [
            'fb_setup_adgroup',
            'fb_aware', 'fb_aware_full'
        ] )
    );

    const set = 'fb_aware';

    return <ColumnSelectTable
        title='Facebook Awareness Audiences'
        url='facebook/audiences/awareness'
        dimensionParams={{ platform: 'facebook', type: 'awareness' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
    />
}
