import React, { Fragment } from 'react';
import { DateRange as MuiDateRange, DateRangePicker as MuiDateRangePicker, DefinedRange } from "materialui-daterange-picker";

import useDate, { DateRange } from '../../hooks/useDate';
import useDebugData from '../../hooks/useDebugData';
// import { time } from 'console';

interface Props {
    start: string;
    end: string;
    max?: string;
    onChange: any;
    ranges?: DefinedRange[];
}

export default function DateRangePicker( props: Props ) {
    // useDebugData('DateRangePicker',props);
    const { addDays, getDate, timezoneOffset } = useDate();

    const [ open, setOpen ] = React.useState( false );

    // if ( !props.start || !props.end ) {
    //     return <></>
    // }

    // Javascript time is in current browser timezone. This has issues with midnight UTC being the previous day for US. So we need to offset by timezone to get midnight UTC time.

    // new Date returns time in CDT. new Date( Date.parse ) returns time in CST which is 1 hour behind in spring. To account for this add an extra hour.
    // This should not impact fall daylight savings because the issue is pulling back 1 hour when the date is midnight. Adding an extra hour will stay in the same day.
    // This must be done here instead of useDate because we don't want to break all times.
    const offset = timezoneOffset + 3600 * 1000;

    const startDate = new Date( Date.parse( props.start ) + offset );
    const endDate = new Date( Date.parse( props.end ) + offset );
    // const startDate = getDate( props.start );
    // const endDate = getDate( props.end );

    const dateRange: MuiDateRange = {
        startDate: startDate,
        endDate: endDate
    };

    // We don't use the offset here because we want the date in their current timezone.
    const today: Date = new Date();
    // const today: Date = new Date( (new Date()).getTime() + offset );

    const dayNumber: number = parseInt( getDate( 'j' ) );

    // Last day of month is number of days. Get number of the day before the
    // first of this month.
    const daysInLastMonth: number = parseInt( getDate( 'j', addDays( today, -1 * dayNumber ) ) );

    const ranges: DefinedRange[] = [
        // {
        //     label: 'Today',
        //     startDate: today,
        //     endDate: today
        // },
        // {
        //     label: 'Yesterday',
        //     startDate: addDays( today, -1 ),
        //     endDate: addDays( today, -1 )
        // },
        {
            label: 'Last 7 Days',
            startDate: addDays( today, -7 ),
            endDate: addDays( today, -1 )
        },
        {
            label: 'Last 14 Days',
            startDate: addDays( today, -14 ),
            endDate: addDays( today, -1 )
        },
        {
            label: 'Last 30 Days',
            startDate: addDays( today, -30 ),
            endDate: addDays( today, -1 )
        },
        {
            label: 'This Month',
            startDate: addDays( today, -1 * dayNumber + 1 ),
            endDate: addDays( today, -1 )
        },
        {
            label: 'Last Month',
            startDate: addDays( today, -1 * ( dayNumber + daysInLastMonth ) + 1 ),
            endDate: addDays( today, -1 * dayNumber )
        },
    ];

    const onToggle = () => setOpen(!open);

    const onChange = ( range: MuiDateRange ) => {
        if ( props.onChange ) {
            props.onChange({
                start: getDate( 'Y-m-d', range.startDate ),
                end: getDate( 'Y-m-d', range.endDate )
            })
        }
    }

    return <Fragment>
        <a onClick={onToggle}>{ getDate( 'M j Y', startDate ) } - { getDate( 'M j Y', endDate ) }</a>

        <MuiDateRangePicker
            initialDateRange={dateRange}
            definedRanges={ props.ranges ?? ranges }
            minDate="1/1/2020"
            maxDate={ props.max ? new Date( props.max ) : new Date() }

            open={open}
            toggle={onToggle}
            onChange={onChange}
        />
    </Fragment>;
}
