import { useEffect, useState } from 'react';

import useAccounts from './useAccounts';
import useApi from './useApi';
import useAuth from './useAuth';
import useFetch from './useFetch';
import useFilters from './useFilters';

export default function useQuery( url: string, params: any = {}, raw: boolean = false ) {
    const { auth } = useAuth();
    const { account } = useAccounts();
    const { filters } = useFilters();
    const { sendGet } = useApi();

    const [ shouldFetch, setShouldFetch ]: any = useState( false );

    const fetchData = async () => {
        let requestParams: any;

        // Pass raw params as is.
        if ( raw ) {
            requestParams = {
                u: auth.id,
                ...params
            };
        } else {
            requestParams = {
                u: auth.id,
                account: account.id,
                platform: params.platform,
            };

            // Add any allowed parameters passed.
            const allowedParams = [ 'fields' ];

            for ( let name of allowedParams ) {
                if ( params[ name ] ) {
                    requestParams[ name ] = params[ name ];
                }
            }

            // These are filters that are added to the root request instead of
            // passed in filters key.
            const rootFilters = [ 'date_start', 'date_end', 'compare_date_start', 'compare_date_end' ];

            // Add ignored filters directly.
            for ( let key of rootFilters ) {
                // Only add if they are set in filters.
                if ( filters[ key ] ) {
                    requestParams[ key ] = filters[ key ];
                }
            }

            // Add other filters.
            requestParams.filters = {};

            for ( let key in filters ) {
                // Don't add ignored filters or 'all' values.
                if ( !rootFilters.includes( key ) && filters[ key ] !== 'all' ) {
                    requestParams.filters[ key ] = filters[ key ];
                }
            }

            if ( params[ 'type' ] ) {
                requestParams.filters[ 'type' ] = params[ 'type' ];
            }
        }

        const response = await sendGet( url, requestParams );

        return response;
    };

    const { data, error, isLoaded, refresh } = useFetch(
        fetchData,
        [ auth.id, url, account.id ],
        shouldFetch
    );

    useEffect(
        () => {
            // Don't make calls if we don't have data.
            if ( !auth.id || !account.id ) {
                return;
            }

            if ( !shouldFetch ) {
                setShouldFetch( true );
            } else {
                refresh();
            }
        },
        [ auth.id, url, account.id ]
    );

    return { data, error, isLoaded, refresh };
}
