import ColumnSelectTable from '../../../../components/ui/ColumnSelectTable';
import useFields from '../../../../hooks/useFields';

export default function Ads() {
    const { getFields, removeFields } = useFields();

    const fieldSets = removeFields(
        [ /^(adgroup|campaign)?_status/ ],
        getFields( [
            'fb_setup_ad',
            'fb_aware', 'fb_aware_full'
        ] )
    );

    const set = 'fb_aware';

    return <ColumnSelectTable
        title='Facebook Awareness Ads'
        url='facebook/ads/awareness'
        dimensionParams={{ platform: 'facebook', type: 'awareness' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
    />
}
