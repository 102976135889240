import { createStore } from 'reusable';
import { useEffect, useState } from 'react';

import useApi from './useApi';

const useBranding = createStore( () => {
    const { sendGet } = useApi();

    const [ branding, setBranding ]: any = useState({});
    const [ error, setError ] = useState('');
    const [ loaded, setLoaded ] = useState(false);

    useEffect( () => {
        (async () => {
            // Don't set if we already loaded
            if ( loaded ) {
                return;
            }

            try {
                let data = await sendGet( 'branding' );

                // Add images.
                data.icon = `/assets/brands/icon-chmw.png`;
                data.logo_brand_light = `/assets/brands/logo-chmw-light.png`;
                data.logo_brand_dark = `/assets/brands/logo-chmw-dark.png`;
                data.logo_client_light = `/assets/clients/${data.code}/logo-light.png`;
                data.logo_client_dark = `/assets/clients/${data.code}/logo-dark.png`;
                data.login_image = `/assets/clients/${data.code}/login-bg.jpg`;

                // Set title.
                document.title = `${data.name} Dashboard | CHMW`;
    
                // Set favicon.
                ( document.getElementById( 'favicon' ) as any ).href = data.icon;

                setBranding( data );
                setLoaded( true );
            } catch ( error: any ) {
                setError( `Unable to load branding: ${error.message}` );
            }
        })();
    }, [ loaded ]);

    return {
        branding,
        loaded,
        error
    };
} );

export default useBranding;
