import useFields from '../../../../hooks/useFields';
import ColumnSelectTable from '../../../../components/ui/ColumnSelectTable';

export default function Audiences() {
    const { getFields, removeFields } = useFields();

    const fieldSets = removeFields(
        [ /^ad_/, /^campaign_status/ ],
        getFields( [
            'fb_setup_adgroup',
            'fb_retailer'
        ] )
    );

    const set = 'fb_retailer';

    return <ColumnSelectTable
        title='Facebook Retailer Audiences'
        url='facebook/audiences/retailer'
        dimensionParams={{ platform: 'facebook', type: 'retailer' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
    />
}
