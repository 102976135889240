import { Fragment } from "react";

import useDate from "./useDate";

// Hooks requires capital name even when hook has basic functionality.
const FormatDate = ( value: any ): string => {
    const { getDate } = useDate();

    const format = {
        dateStyle: 'short',
    };

    return getDate( 'n/j/y', value );
}

const formatDateHelper = ( value: any, format: Object ): string => {
    if ( !value ) {
        return '';
    }

    const formatter = new Intl.DateTimeFormat( 'en-US', format );

    return formatter.format( new Date( value ) );
}

const FormatDateTime = ( value: any ): string => {
    if ( !value ) {
        return '';
    }

    const formatter = new Intl.DateTimeFormat( 'en-US', {
        dateStyle: 'short',
        timeStyle: 'long'
    } );

    return formatter.format( new Date( value ) );
}

const formatMoney = ( value: number, digits: number = 2 ): any => {
    const formatter = new Intl.NumberFormat( 'en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    })

    return formatter.format( value );
}

const formatNumber = ( value: number, digits: number = 0 ): any => {
    const formatter = new Intl.NumberFormat( 'en-US', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    })

    return formatter.format( value );
}

const formatPercent = ( value: number, digits: number = 2 ): any => {
    const formatter = new Intl.NumberFormat( 'en-US', {
        style: 'percent',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    })

    return formatter.format( value / 100 );
}

const formatType = ( type: string | null, value: any, precision: number = 0 ): any => {
    // If no type, pass alue as is.
    if ( type === null ) {
        return value;
    }

    // Show null and undefined values as blank.
    if ( value === null || typeof( value ) === 'undefined' ) {
        return '';
    }

    if ( type === 'boolean' ) {
        return value ? 'Yes' : 'No';

    } else if ( type === 'date' ) {
        return FormatDate( value );

    } else if ( type === 'files' ) {
        const html = value
            .replace( /([^\n]+?)\t([^\n]+)/g, '<a href="$2" target="_blank" rel="noreferrer">$1</a>' )
            .replace( /\n/g, '<br>' );

        return <div className="text-wrap" dangerouslySetInnerHTML={{ __html: html }}></div>;

    } else if ( type === 'html' ) {
        return <div dangerouslySetInnerHTML={{ __html: value }}></div>;

    } else if ( type === 'image' ) {
        return <img src={value} alt='' />;

    } else if ( type === 'link' ) {
        const html = value
            .replace( /(https?:\S+)/g, '<a href="$1" target="_blank" rel="noreferrer">$1</a>' )
            .replace( /\n/g, '<br>' );

        return <div className="text-wrap" dangerouslySetInnerHTML={{ __html: html }}></div>;

    } else if ( type === 'fb_links' ) {
        const order = [ 'Preview', 'Post', 'ID', 'Name' ];

        const html = <span>
            { order.map( ( name: string ) => {
                const key = name.toLowerCase();

                return value[ key ]
                    ? <Fragment key={key}><a href={value[ key ]} target="_blank" rel="noreferrer">{ name }</a>&nbsp;</Fragment>
                    : null
            } ) }
        </span>;

        return html;

    } else if ( type === 'money' ) {
        return isNumeric( value ) ? formatMoney( value, precision ? precision : 2 ) : value;

    } else if ( type === 'number' ) {
        return isNumeric( value ) ? formatNumber( value, precision ) : value;

    } else if ( type === 'percent' ) {
        return isNumeric( value ) ? formatPercent( value, precision ) : value;

    } else if ( type === 'text' ) {
        return value;

    } else if ( type === 'text_wrap' ) {
        return <div className="text-wrap" dangerouslySetInnerHTML={{__html: value.replace( /\n/g, '<br>' )}}></div>;

    } else if ( type === 'time' ) {
        return FormatDateTime( value );
    }

    throw new Error( 'Unknown format type: ' + type );
}

const isNumeric = ( value: any ): boolean => {
    const result = value == parseFloat( value );

    return result;
}

export default function useFormat() {
    return {
        formatDate: FormatDate,
        formatDateTime: FormatDateTime,
        formatMoney,
        formatNumber,
        formatPercent,
        formatType
    };
}
