import useAuth from './useAuth';

export default function useMenu() {
    const { auth } = useAuth();

    return {
        user: {
            name: auth.name,
            username: auth.username,
            initials: auth.initials
        }
    };
}
