import { useEffect, useState } from 'react';

export default function useFetch( callback: () => {}, dependencies: any[] = [], shouldFetch: boolean ) {
    const [ data, setData ]: any = useState( null );
    const [ error, setError ]: [ string | null, any ]  = useState( null );
    const [ isLoaded, setIsLoaded ]: [ boolean, any ] = useState( false );

    const refresh = () => {
        setIsLoaded( false );
    }

    useEffect( () => {
        (async () => {
            if ( !shouldFetch || isLoaded ) {
                return;
            }

            try {
                // setIsLoaded( false );
                const response = await callback();

                setData( response );
                setIsLoaded( true );
            } catch ( error: any ) {
                setError( error.message );
            }
        })();
    }, [ ...dependencies, shouldFetch, isLoaded ] );

    return {
        data,
        error,
        isLoaded,
        refresh
    };
}
