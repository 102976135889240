import { TabCards } from '../../../../components/ui/TabCards';
import useFields from '../../../../hooks/useFields';

export default function Summary() {
    const { getFieldsFromTabCards, getLayout } = useFields();

    const tabs: any = [
        {
            name: 'Overview',
            cards: getLayout( [
                [ 'spend' ],
                [ 'impressions', 'reach', 'cpm' ],
                // 'frequency'
                [ 'link_clicks', 'link_ctr', 'cost_per_link_click' ],
                [ 'clicks', 'ctr', 'post_engagements', 'engagement_rate' ]
            ] )
        },
    ];

    return <TabCards
        title='Facebook Retailer'
        api='facebook/summary/retailer'
        params={{ fields: getFieldsFromTabCards(tabs).join( ',' ) }}
        dimensionParams={{ platform: 'facebook', type: 'retailer' }}
        tabs={tabs}
        enableCompare={true}
        enableSave={true}
    />
}
