import useAccounts from '../hooks/useAccounts';
import useFilters from '../hooks/useFilters';

import { menus } from '../clients';

export default function useMenu() {
    const { accounts, account, setAccount } = useAccounts();
    const { filters, setFilter, clearFilters } = useFilters();

    const currentMenus = menus[ account.code ] ?? [] ;

    const icons: any = {
        facebook: 'M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z',
        google: 'M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z',
        // klaviyo: `M57.1,267.3l113.8-75.4c4.9-2.9,11.1-2.9,16,0l113.8,75.4c4.5,2.2,6.4,7.6,4.2,12.1c-0.9,1.8-2.4,3.3-4.2,4.2 l-15.2,10c-37.3-58.9-115.2-76.4-174.1-39.1c-15.8,10-29.2,23.4-39.1,39.1l-15.2-10c-4.5-2.3-6.3-7.8-4-12.3 C53.9,269.6,55.4,268.2,57.1,267.3L57.1,267.3z M178.8,266.4c-32.7,0.1-63,16.9-80.4,44.5l26.2,17.4c18-29.8,56.8-39.4,86.6-21.4 c8.8,5.3,16.1,12.6,21.4,21.4l26.2-17.4C241.7,283.2,211.4,266.4,178.8,266.4z M178.8,329.4c-11.5,0.1-22.1,6.3-27.8,16.4l17.6,11.5 c2.9,2.4,6.5,3.7,10.2,3.8c3.8,0,7.4-1.3,10.2-3.8l17.6-11.5C201,335.7,190.4,329.4,178.8,329.4z`,
        magento: 'M12 24l-4.455-2.572v-12l2.97-1.715v12.001l1.485.902 1.485-.902V7.713l2.971 1.715v12L12 24zM22.391 6v12l-2.969 1.714V7.713L12 3.43 4.574 7.713v12.001L1.609 18V6L12 0l10.391 6z',
        pinterest: 'M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z',
        shopify: 'M15.337 23.979l7.216-1.561s-2.604-17.613-2.625-17.73c-.018-.116-.114-.192-.211-.192s-1.929-.136-1.929-.136-1.275-1.274-1.439-1.411c-.045-.037-.075-.057-.121-.074l-.914 21.104h.023zM11.71 11.305s-.81-.424-1.774-.424c-1.447 0-1.504.906-1.504 1.141 0 1.232 3.24 1.715 3.24 4.629 0 2.295-1.44 3.76-3.406 3.76-2.354 0-3.54-1.465-3.54-1.465l.646-2.086s1.245 1.066 2.28 1.066c.675 0 .975-.545.975-.932 0-1.619-2.654-1.694-2.654-4.359-.034-2.237 1.571-4.416 4.827-4.416 1.257 0 1.875.361 1.875.361l-.945 2.715-.02.01zM11.17.83c.136 0 .271.038.405.135-.984.465-2.064 1.639-2.508 3.992-.656.213-1.293.405-1.889.578C7.697 3.75 8.951.84 11.17.84V.83zm1.235 2.949v.135c-.754.232-1.583.484-2.394.736.466-1.777 1.333-2.645 2.085-2.971.193.501.309 1.176.309 2.1zm.539-2.234c.694.074 1.141.867 1.429 1.755-.349.114-.735.231-1.158.366v-.252c0-.752-.096-1.371-.271-1.871v.002zm2.992 1.289c-.02 0-.06.021-.078.021s-.289.075-.714.21c-.423-1.233-1.176-2.37-2.508-2.37h-.115C12.135.209 11.669 0 11.265 0 8.159 0 6.675 3.877 6.21 5.846c-1.194.365-2.063.636-2.16.674-.675.213-.694.232-.772.87-.075.462-1.83 14.063-1.83 14.063L15.009 24l.927-21.166z',
        snapchat: 'M12.206.793c.99 0 4.347.276 5.93 3.821.529 1.193.403 3.219.299 4.847l-.003.06c-.012.18-.022.345-.03.51.075.045.203.09.401.09.3-.016.659-.12 1.033-.301.165-.088.344-.104.464-.104.182 0 .359.029.509.09.45.149.734.479.734.838.015.449-.39.839-1.213 1.168-.089.029-.209.075-.344.119-.45.135-1.139.36-1.333.81-.09.224-.061.524.12.868l.015.015c.06.136 1.526 3.475 4.791 4.014.255.044.435.27.42.509 0 .075-.015.149-.045.225-.24.569-1.273.988-3.146 1.271-.059.091-.12.375-.164.57-.029.179-.074.36-.134.553-.076.271-.27.405-.555.405h-.03c-.135 0-.313-.031-.538-.074-.36-.075-.765-.135-1.273-.135-.3 0-.599.015-.913.074-.6.104-1.123.464-1.723.884-.853.599-1.826 1.288-3.294 1.288-.06 0-.119-.015-.18-.015h-.149c-1.468 0-2.427-.675-3.279-1.288-.599-.42-1.107-.779-1.707-.884-.314-.045-.629-.074-.928-.074-.54 0-.958.089-1.272.149-.211.043-.391.074-.54.074-.374 0-.523-.224-.583-.42-.061-.192-.09-.389-.135-.567-.046-.181-.105-.494-.166-.57-1.918-.222-2.95-.642-3.189-1.226-.031-.063-.052-.15-.055-.225-.015-.243.165-.465.42-.509 3.264-.54 4.73-3.879 4.791-4.02l.016-.029c.18-.345.224-.645.119-.869-.195-.434-.884-.658-1.332-.809-.121-.029-.24-.074-.346-.119-1.107-.435-1.257-.93-1.197-1.273.09-.479.674-.793 1.168-.793.146 0 .27.029.383.074.42.194.789.3 1.104.3.234 0 .384-.06.465-.105l-.046-.569c-.098-1.626-.225-3.651.307-4.837C7.392 1.077 10.739.807 11.727.807l.419-.015h.06z',
        tiktok: 'M12.53.02C13.84 0 15.14.01 16.44 0c.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z',
    };

    const capitalize = ( text: string ): string => text
        .split( /_/ )
        .map( ( item: string ) => item.charAt(0).toUpperCase() + item.slice(1) )
        .join( ' ' )

    const userLevels: { [key: string]: string } = {
        admin: 'verified_user',
        manager: 'account_circle',
        user: 'face',
        client_admin: 'verified_user',
        client_manager: 'account_circle',
        client_user: 'face',
    };

    let menu: any = [
        {},
        {
            name: capitalize( filters[ 'view' ] ?? 'Not Set' ),
            icon: 'visibility',
            level: 'manager',
            children: Object.keys( userLevels ).map( (key: string) => ({
                name: capitalize( key ),
                icon: userLevels[ key ],
                autoclose: true,
                onClick: () => setFilter( 'view', key )
            }))
        },
        {},
        // {
        //     name: 'Home',
        //     icon: 'home',
        //     link: ''
        // },

        {
            name: 'System',
            icon: 'settings',
            view: 'manager',
            children: [
                {
                    name: 'Cache',
                    icon: 'signal_cellular_alt',
                    view: 'manager',
                    children: [
                        {
                            name: 'Scan',
                            icon: 'home',
                            link: 'admin/system/cache'
                        },                
                        {
                            name: 'Status',
                            icon: 'home',
                            view: 'admin',
                            link: 'admin/system/status'
                        },                
                        // {
                        //     name: 'Crawler',
                        //     icon: 'home',
                        //     link: 'admin/system/status'
                        // },                
                    ]
                },
                {
                    name: 'Users',
                    icon: 'supervised_user_circle',
                    view: 'admin',
                    link: 'admin/users'
                },

                // {
                //     name: 'Setup',
                //     icon: 'settings',
                //     children: [
                //         {
                //             name: 'Drilldowns',
                //             icon: 'pie_chart',
                //             link: 'admin/facebook/setup/drilldowns'
                //         },
                //         {
                //             name: 'Audiences',
                //             icon: 'account_tree',
                //             link: 'admin/facebook/setup/audiences'
                //         },   
                //     ]
                // },
                // {
                //     name: 'Budgets',
                //     icon: 'monetization_on',
                //     children: [
                //         {
                //             name: 'Months',
                //             icon: 'today',
                //             link: 'admin/facebook/budgets/months'
                //         },
                //         {
                //             name: 'Pacing',
                //             icon: 'speed',
                //             link: 'admin/facebook/budgets/pacing'
                //         },
                //         {
                //             name: 'Min Spends',
                //             icon: 'filter_hdr',
                //             link: 'admin/facebook/budgets/min-spends'
                //         }        
                //     ]
                // },
                // {
                //     name: 'Users',
                //     icon: 'supervised_user_circle',
                //     link: 'admin/users'
                // }
            ]
        },
        {
            name: 'Internal Tools',
            icon: 'build',
            view: 'user',
            children: [
                {
                    name: 'Monthly Pacing',
                    icon: 'speed_icon',
                    link: 'admin/tools/pacing'
                },
                {
                    name: 'Budget Calculator',
                    icon: 'dialpad',
                    link: 'admin/tools/budget-calculator'
                },
                // {
                //     name: 'UTM Parser',
                //     icon: 'dialpad',
                //     link: 'admin/tools/utm-parser'
                // },
            ]
        },
        {},

        ...currentMenus,

        {},
        {
            name: 'Logout',
            icon: 'exit_to_app',
            link: 'logout'
        }
    ];


    menu.unshift({
        name: account.name,
        icon: 'aspect_ratio',

        // Always show account but don't add list if only one.
        children: accounts.length === 1 ? [] :
            accounts.map( ( item: any ) => {
            return {
                name: item.name,
                icon: 'aspect_ratio',
                active: account.code === item.code,
                autoclose: true,
                onClick: () => {
                    setAccount( item );
                    clearFilters();
                }
            };
        } )
    });

    // Add SVG icons.

    for ( let i in menu ) {
        if ( menu[ i ].icon_svg && icons[ menu[ i ].icon_svg ] ) {
            menu[ i ].icon_svg = icons[ menu[ i ].icon_svg ];
        }
    }

    return {
        menu
    };
}
