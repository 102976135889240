/**
 * Query params are shortened to params since they're more common. Router params would be routeParams.
*/
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createStore } from 'reusable';

// const addQueryParam = ( queryString: string, key: string, value: any ) => {
//     let params = getParams( queryString );

//     params.set( key, value );

//     return params;
// }

const getParams = ( queryString: string ): URLSearchParams => {
    // Get query params object.
    const params = new URLSearchParams( queryString );

    return params;
}

const getParamsObject = ( params: URLSearchParams ) => {
    let object: any = {};

    for ( let [key, value] of params.entries() ) {
        object[ key ] = value;
    }

    return object;
}

const useRouter = createStore( () => {
    const history = useHistory();
    const location = useLocation();

    const [ params, setAllParams ] = useState( getParams( location.search ) );

    const setParam = ( key: string, value: any ) => {
// console.log( 'add', key, '=', value )

        setAllParams( ( previous: URLSearchParams ) => {
            // Avoid state update if data stays the same.
            if ( previous.get( key ) === value ) {
                return previous;
            }

            previous.set( key, value );

            history.push({ search: previous.toString() });

            return previous;
        } );
    }

    const setParams = ( data: { [ key: string ]: any } ) => {
        setAllParams( ( previous: URLSearchParams ) => {
            // Avoid state update if data stays the same.
            let matchCount = 0;

            for ( let key in data ) {
                if ( previous.get( key ) === data[ key ] ) {
                    matchCount++;
                }
            }

            if ( matchCount === Object.keys( data ).length ) {
                return previous;
            }

            let newParams = previous;

            // This must be looped separately because URLSearchParams is passed by reference and calling set() on a copy changes the original.
            for ( let key in data ) {
                if ( newParams.get( key ) !== data[ key ] ) {
                    newParams.set( key, data[ key ] );
                }
            }

            history.push({ search: newParams.toString() });

            return newParams;
        } );
    }

//     useEffect( () => {
//         setAllParams( ( previous: any ) => {
// console.log( 'effect router', getParamsObject( previous ), "\nto", getParamsObject( getParams( location.search ) ) );
//             return getParams( location.search );
//         } );

//     }, [ location, history ] );

    return {
        history,
        location,
        params: getParamsObject( params ),
        setParam,
        setParams
    };
} );

export default useRouter;
